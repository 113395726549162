code {
    font-family: Arial, Helvetica, sans-serif;
}

/* variables de colores duppla*/
:root {
    --color-primario-btn: #6C9FFF;
    --color-fondo: #FFFFFF;
    --color-naranja-duppla: #FF864B;
    --color-verde-duppla: #C5F5CA;
    --color-cimiento-duppla: #0A3323;
    --color-sombra-duppla: #EEEFF3;
    --color-fondo-gris: #F9F9F9;
}

@font-face {
    font-family: 'Rustica';
    src: url('./../fonts/Rustica/RusticaMedium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Rustica';
    src: url('./../fonts/Rustica/RusticaLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Rustica';
    src: url('./../fonts/Rustica/RusticaRegular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNowText-Light';
    src: url('./../fonts/HelveticaNow/HelveticaNowTextLight.otf') format('opentype');
    font-weight: 200;
    font-style: normal;
}

.text-blue-init {

    color: var(--color-primario-btn)
}

.text-green-init {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    color: var(--color-cimiento-duppla);
}

/*Sección Perfil inicio custumer*/
.profile-inicio {
    display: flex;
    flex-direction: initial;
    justify-content: space-between;
    align-items: center;

    left: 0px;
    top: 0px;
    bottom: 100px;
    padding: 0px 0px 0px 0px;
    margin-bottom: 31px;

    background: var(--color-fondo);
    background-size: 100%;
    background-repeat: no-repeat;
    border-bottom-right-radius: 16%;
    border-bottom-left-radius: 16%;

}


.img-user-init {
    width: 64px;
    height: 63px;
    margin-top: 30px;
    margin-left: 16px;
    margin-bottom: 10px;

}

.container-progress {

    align-items: center;
    height: 800px;
    margin-left: 32px;
    margin-right: 32px;
    background: #FFFFFF;
    border-radius: 20px;
}

.title-init {
    padding-top: 6px;
}

.title-init-progressbar {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 24px;
    font-family: 'Rustica' !important;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;

    font-family: Arial, Helvetica, sans-serif;
    color: #0A3323;

}

.progres-img {
    margin-top: 20px;
    width: 340px;
    height: 160px;
}

.text-align-custumer {
    margin-top: 10px;
}

.text-number-custumer {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
}

.text-space-month {
    margin-top: 13px;
    margin-left: 25px;
}

.text-space-property {
    width: 100px;
    margin-top: 10px;
    margin-left: 10px;
    color: #6C9FFF;
    font-size: 16px;
}


.card-docs-init {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;

    padding: 16px 24px;
    gap: 16px;
    height: 20px;
    margin-left: 16px;
    margin-right: 16px;

    border-radius: 5px;
    top: 210px;
    bottom: 100px;

}

.card-docs-init-customer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -5px;
    margin-bottom: 5px;

    padding: 16px 24px;
    gap: 16px;
    height: 20px;
    margin-left: 40px;
    margin-right: 20px;

    border-radius: 5px;
}

.card-docs-property {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 5px;
    margin-bottom: 5px;

    padding: 10px 10px;
    width: 140px;
    height: 20px;
    border-radius: 5px;
}

.card-body-property {
    width: 110px;
    margin-left: -10px;
}

.text-dropdown-property {
    width: 80px;
    margin-left: -10px;
    margin-right: 4px;
}

.container-movil {
    position: relative;
}

.prueva-mo {
    position: absolute;
}

.card-header {
    border: none !important;
    position: relative;
    width: 290px;
}

.card {
    border: none !important;
    margin-left: -38px;
}

.card-col-none {
    border: none !important;
    border-color: #ffffff;
    border: 1px solid #ffffff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}

.card-dropdown-property {
    background-color: #ffffff;
}


.btn-pago-custumer {
    width: 365px;
    height: 58px;
    margin-top: 20px;
    margin-bottom: 20px;
    background: linear-gradient(0deg, #81A1F8, #81A1F8), #81A1F8;
    border: 1px solid #81A1F8;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
}


.container-form-centrado-customer-card {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-left: 16px;
    margin-right: 16px;
    border-radius: 5px;

}

.accordion-item-mui {
    width: 378px !important;

}

.ul {
    text-decoration: none;
    list-style: none;
    border: #F9F9F9;
}

.text-space-dropdown {
    margin-right: 60px;
}

.icon-drop {
    margin-left: 250px;
}

.icon-drop-property {
    margin-left: 124px;
}

.dropdown-menu-init {
    padding: 0px 0px 0px 0px;
    box-sizing: border-box;
    width: 262px;
    height: 290px;
    margin-right: -7px;
    margin-left: 150px;
    background: #ffffff;
    border: 1px solid #E1EBF4;
    border-radius: 8px;
}

.card-dropdown {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    margin-bottom: 5px;

    padding: 16px 24px;
    gap: 16px;
    height: 46px;
    margin-left: 16px;
    margin-right: 16px;

    top: 210px;
    bottom: 100px;

    background-color: var(--color-fondo);
    border: 1px solid #E1EBF4;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
}

.acordion-tamaño {
    width: 360px;
}


.line-custumer {
    height: 2px;
    width: 380px;
}

.img-btn-pagos-custumer {
    height: 32px;
    width: 32px;
    margin-right: 10px;
    margin-bottom: 10px;
}

.container-btns {
    margin-top: 60px;
}

.text-btn-custumer-menu {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
}

.navbar-custumer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 4px;

    position: absolute;
    width: 32px;
    height: 42px;
    margin-left: 10px;
    margin-right: 10px;
    border-color: #6C9FFF;
}

.btn-modal-cerrar {

    margin: 30px 10px 10px 25px;
}

.btn-modal-pago {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px 16px;
    gap: 10px;


    width: 220px;
    height: 120px;
    left: 97px;
    top: 380px;

    background: #FFFFFF;
    border: 1px solid #6C9FFF;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
}

.text-blue-modal {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    color: var(--color-primario-btn);
}

/*  nuevo */


.container-btn-wrapper {
    max-width: 600px;
    border: 1px solid var(--color-fondo);
    display: flex;
    overflow-x: auto;
    margin-top: 20px;


}

.container-btn-wrapper::-webkit-scrollbar {
    width: 0px;

}

.btn-wrapper {

    width: 70px;
    height: 70px;
    margin-left: 15px;
    top: 0px;
    background: #EFEFEF;
    border-radius: 36px;
}

.btn-wrapper-one {

    width: 70px;
    height: 70px;
    margin-left: 6px;

    background: #EFEFEF;
    border-radius: 36px;
}

.img-btn-wrapper {
    margin-left: 19px;
    margin-right: 5px;
    margin-top: 18px;
    margin-bottom: 5px;
}

.img-btn-wrapper-history {

    margin-left: 23px;
    margin-right: 6;
    margin-top: 25px;
    margin-bottom: 5px
}

.img-btn-wrapper-problem {

    margin-left: 17px;
    margin-right: 6;
    margin-top: 17px;
    margin-bottom: px
}


.text-btn-wrapper {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    text-align: center;
}

.space-btn-wrapper {
    margin-left: 19px;
    margin-right: 17px;
    margin-top: 15px;
    margin-bottom: 5px;



}


.btn-disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.btn-disabled-offert {
    background-color: #ffffff;
    color: #999;
    cursor: not-allowed;
}

.btn-custumer-disabled {
    border: none !important;
}

.card-new {

    width: 340px;


}

.card {
    width: 340px;
    margin-left: 4px;
    margin-right: 10px;
    background-color: #ffffff;

}


.text-space-card-c {
    width: 120px;

}

.card-body-docs-c {
    width: 186px;
    margin-left: -10px;
    background-color: rgb(255, 255, 255);
}


.span-pago {
    margin-left: 24px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.text-space-custumer {
    width: 260px;
    margin-left: 1px;
    margin-right: 3px !important;

}

.text-space-property {

    margin-left: 50px;
    margin-right: 10px !important;

}

.space-title-p {
    width: 80px;
}
.space-title-prueba-mui{
    margin-left: -60px !important;
}

.space-title-docs-home {
    width: 240px;
    margin-top: -6px !important;
    margin-left: 29px;
    
}

.space-title-docs-home-mui {
    width: 240px;
    margin-top: -6px !important;
    margin-left: 60px !important;
    
}
.acordion-item-border {
    border: none !important;
    margin-top: -10px !important;
}

.accordion-custumer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    margin-bottom: 5px;

    margin-left: 40px;
    margin-right: 40px;

    border: 1px solid #E1EBF4;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
}

.accordion-property {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    margin-bottom: 5px;

    margin-left: 40px;
    margin-right: 40px;

    border: none;

    border-radius: 12px;
}


.container-inicio-graph {

    display: flex;
    justify-content: center;
    align-items: center;

    margin-right: 20px;
    margin-left: 20px;
    margin-top: 50px;

    background-color: rgb(255, 255, 255);
    /* Stroke Color */
    z-index: 1;

}

.grafict-container-inicio-one {

    width: 374px;
    height: 540px;
    margin-right: 74px;
    margin-left: 30px;
    margin-bottom: 10px;
    background: #ffffff;
    /* Stroke Color */

    border: 1px solid #EFF0F6;
    /* Tile Dropshadow */

    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    z-index: 1;
}

.text-inicio-gra {

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;

    font-size: 14px;
    margin-top: 5px;
    margin-left: -1px;
    width: 150px;
    line-height: 16px;
    /* identical to box height, or 145% */

    display: flex;
    align-items: center;
    letter-spacing: 0.15px;
}

.prueba-espacio-img-verde {
    margin-left: 10px;
}

.prueba-inicio-espacio-uno {
    background-color: #0A3323;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    color: #0A3323;
    height: 58px;
    width: 80px;
}

.prueba-inicio-espacio-dos {
    background-color: #C5F5CA;
    color: #C5F5CA;
    height: 58px;
    width: 160px
}

.prueba-inicio-espacio-tres {
    background-color: #81A1F8;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    height: 58px;
    width: 80px;
}

.card-docs-grafic {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 130px;
    margin-bottom: 5px;

    padding: 16px 24px;
    gap: 16px;
    height: 20px;
    margin-left: 40px;
    margin-right: 20px;
    border-radius: 5px;
    top: 210px;
    bottom: 100px;
}

.card-docs-grafic-two {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -10px;
    margin-bottom: 2px;

    padding: 16px 24px;
    gap: 16px;
    height: 20px;
    margin-left: 40px;
    margin-right: 20px;

    border-radius: 5px;
    top: 210px;
    bottom: 100px;
}


.sobrepuesto {
    z-index: 2;
    margin-top: -262px;
    margin-left: 2px;
    flex-direction: row;
    justify-content: center;
    text-align: center;
}

.tooltip-container {
    position: relative;
}

.tooltip {
    position: absolute;
    margin-top: -24px;
    margin-left: 120px;
    width: 200px;
    transform: translateX(-50%);
    background-color: #ffffff;
    color: #0A3323;
    padding: 8px;
    border-radius: 4px;
    font-size: 14px;
    opacity: 1;
    box-shadow: 0px 0px 10px rgba(115, 113, 113, 0.15);
}


.space-tooltip-mui{
    margin-top: -10px !important;
    margin-left: -8px;
}

.tooltip-grafict {
    position: absolute;
    margin-top: -15px;
    margin-left: 40px;
}

.tooltip-grafict-two {
    position: absolute;
    margin-top: -15px;
    margin-left: 10px;
}

.tooltip-grafict-custumer {
    position: absolute;
    margin-top: -24px;
    margin-left: 142px;
    width: 160px;
    transform: translateX(-50%);
    background-color: #ffffff;
    color: #0A3323;
    padding: 8px;
    border-radius: 4px;

    opacity: 1;
    box-shadow: 0px 0px 10px rgba(115, 113, 113, 0.15);
}

.tooltip-text-c {
    font-family: 'Roboto';
    font-style: normal;
    font-size: 11px;

}



.tooltip-customer {
    width: 20px;
    margin-left: 18px;
}

.space-title-dop {
    width: 120px !important;
    margin-left: -20px;
}


.navbar-toggler {
    border: none !important;
}

.navbar-toggler:focus {
    box-shadow: none !important;
}

.navbar-move-bell {
    height: 250px !important;
    border-radius: 12px 12px 0px 0px;
}

.img-duppla-custumer {

    padding: 0px;
    width: 80px;
    height: 28px;
    margin-left: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.container-first-elements {
    width: auto;
    height: 58px;
}

.bell {
    fill: #FFFFFF;
}

.menu-navbar-customer {
    fill: #FFFFFF;

}

.bell {
    height: 24px;
    width: 24px;
    margin-left: 160px;
    margin-right: 30px;
    margin-top: -52px;

}

.container-second-elements {
    width: auto;
    height: 58px;

}

.card-perfil-datos-customer {
    margin-top: -90px;
    margin-bottom: 15px;

}

.icon-navbar-customer {
    margin-bottom: 29px;
    margin-left: -15px;
    margin-top: -72px;


}

.tooltip {
    font-family: 'Roboto';
    font-size: 11px;
}

.horizontal-line {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 280px;
    margin-left: 40px;
    margin-right: 20px;
    height: 0px;
    border: 1px solid #E8E8E8;
}

