html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden !important;
  width: 100% !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Rustica', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Rustica'
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Arial, Helvetica, sans-serif;
}

/* variables de colores duppla*/
:root {
  --color-primario-btn: #6C9FFF;
  --color-fondo: #FFFFFF;
  --color-naranja-duppla: #FF864B;
  --color-verde-duppla: #C5F5CA;
  --color-cimiento-duppla: #0A3323;
  --color-sombra-duppla: #EEEFF3;
  --color-fondo-gris: #F9F9F9;
}

@font-face {
  font-family: 'Rustica';
  src: url('./../src/fonts/Rustica/RusticaMedium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Rustica';
  src: url('./../src/fonts/Rustica/RusticaLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Rustica';
  src: url('./../src/fonts/Rustica/RusticaRegular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Rustica';
  src: url('./../src/fonts/Rustica/RusticaRegular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNowText-Light';
  src: url('./../src/fonts/HelveticaNow/HelveticaNowTextLight.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
}

/*Configuración container*/
.container-fluid {
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
}

.container {
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
}

.container-sm {
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
}

.container-md {
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
}

/*Estilos para el componente sing in*/

.btn-registro {
  color: var(--color-fondo);
  font-family: Arial, Helvetica, sans-serif;
}

.b-color-gris {
  color: var(--color-fondo-gris)
}

.card-title {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
}

.card-title-home {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
}

.card-title-home-mui {
  margin-top: 20px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
}

.card-text {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  margin-top: 25px;
}

.card-text-grid {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  margin-top: 5px;
  width: 100%;
}

.card-text-grid-prc {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  margin-top: 4px;
  width: 100%;
}

.card-text-docs {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  margin-top: 4px;
}

.card-text-aprov {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  margin-top: 4px;
}

.card-text-aprov-grid {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  margin-top: -11px;
}

.container-sing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 120px;
  padding: 10px;

  height: auto;
}

.img-logotipo {
  width: 327px;
  height: 269px;
  left: 24px;
  top: 180px;

}

.btn {
  border-radius: 5px;

}

.btn-registro {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 315px;
  height: 46px;
  border-radius: 10px;
  box-shadow: 0 5 15 0 var(--color-sombra-duppla);
  margin-top: 50px;
  margin-bottom: 16px;
  background: var(--color-primario-btn);

}

.centrado-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 315px;
  height: 46px;
  margin-bottom: 50px;
}

.btn-center-home {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-ingreso-google {
  background-color: #FFFFFF;
  margin-top: 16px;
  margin-bottom: 16px;
  border-radius: 10px;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.16);
}

.btn-ingreso-facebook {
  background-color: #ffffff;
  ;
  margin-bottom: 16px;
  border-radius: 10px;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.16);

}

.img-ingreso {
  padding: 5px;
  margin-right: 18px;
}

.link-btn {
  text-decoration: none;
}

.btn-decoration {
  text-decoration: none;
}

/*Estilos registro email y contraseña*/

.register {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.container-register {

  width: 420px;
  height: auto;
  margin-top: 30px;
  margin-left: 10px;
  margin-right: 10px;

}

.arrow-return {
  margin-top: 10px;
  text-decoration: none;
  color: #000000;
}

.title-register {
  padding: 0 0 5px 5px;
  margin-top: 20px;
  text-align: start;
  margin-bottom: 20px;
  margin-left: 15px;
  color: #0A3323;

}

.element-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Alinear elementos en el centro horizontalmente */
  width: 100%;
  /* Establecer el ancho del contenedor */
  max-width: 800px;
  /* Puedes ajustar este valor según tus necesidades */
  margin: 0 auto;
  /* Centrar el contenedor en la página */
}

.title-login {
  padding: 0 0 5px 5px;
  margin-top: 48px;
  text-align: start;
  margin-bottom: -20px;


  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 31px;
  display: flex;

  letter-spacing: -0.015em;
  color: #0A3323
}

.MuiTooltip-tooltip {
  background-color: #95B1FF !important;
  /* Color del fondo del contenido */
  color: white !important;
  /* Color del texto dentro del tooltip */
}

.form-register {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
}

.btn-mover {
  margin-left: 8px;
}

.input-register {
  width: 352px;
  height: 52px;
  margin-top: 10px;
  margin-bottom: 10px;

}

/*Estilos perfil*/
.container-profile {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  background: #F5F5F5;
  height: 1200px;

}

.profile-data {
  display: flex;
  flex-direction: initial;
  justify-content: space-between;
  align-items: center;
  height: 110px;
  left: 14px;
  top: 70px;
  bottom: 100px;
  background: linear-gradient(138.67deg, #0A3323 16.71%, #C5F5CA 162.89%);
  border-radius: 5px;
}


/*Manejo img de perfil y home*/
.img-fluid {
  width: 358px;
  height: 170px;
  margin-left: 16px;
  margin-right: 16px;

}

/*Manejo img de perfil y home*/
.img-duppla-naranja {
  width: 80px;
  height: 30px;
}

.img-user {
  width: 64px;
  height: 63px;
  margin-left: 32px;
  margin-top: 25px;
}

.img-user-mui {
  width: 64px;
  height: 63px;

}

.img-user-custumer {
  width: 64px;
  height: 63px;
  margin-left: 32px;
  margin-top: -20px;
}

.img-user-img {
  width: 64px;
  height: 63px;
  margin-top: 30px;
  margin-left: 16px;
  margin-bottom: 10px;
}

.contenedor-img-duppla {
  width: 80px;
  height: 30px;
}

.img-duppla {


  width: 80px;
  height: 28px;
  left: 278px;
  top: 20px;
  margin-left: 20px;
  margin-right: 24px;
}

.card-home {
  margin-top: 75px;
}

.img-state-progress {
  width: 32px;
  height: 32px;
  margin-top: 18px;
  margin-left: 16px;
  margin-bottom: 18px;

}

.text-state-progress {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
}

.text-profile-mui {
  margin-top: 24px;
}

.user-data-card {

  background-color: var(--color-fondo);
  height: 100%;


  background: #FFFFFF;
  box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
}

.size-text-card-profile-mui {
  margin-top: 10px;
}

/*sección datos en perfil*/

.card-perfil {
  margin-top: 27px;
  margin-bottom: 30px;
}

.links {
  text-decoration: none;
}

/* linea en perfil |*/
/* hr {

  width: 10px;
  height: 0px;
  left: 100px;
  top: 65px;
  margin-top: 30px;
  margin-left: 10px;
  border: 1px solid #FFFFFF !important;
  transform: rotate(90deg);
  background-color: #ffffff !important;


} */

.more {
  width: 110px;


}

.link-styles {

  color: var(--color-fondo);
  left: 25px;
  font-size: 14px;
  font-weight: 500;
}


/*Estilos inicio o home aplicación*/


/* tarjeta perfil home*/
.profile {
  display: flex;
  flex-direction: initial;
  justify-content: space-between;
  align-items: center;

  height: 177px;
  left: 0px;
  top: 0px;
  bottom: 100px;
  padding: 0px 0px 0px 0px;
  margin-bottom: 31px;

  background: linear-gradient(60.01deg, #0A3323 19.88%, #C5F5CA 217.71%);
  background-size: 100%;
  background-repeat: no-repeat;
  border-bottom-left-radius: 60% 18%;
  border-bottom-right-radius: 60% 18%;

}

.profile-custumer {
  position: relative;
  display: grid;
  height: 177px;
  padding: 0px 0px 0px 0px;
  margin-bottom: 31px;
  background: linear-gradient(60.01deg, #0A3323 19.88%, #C5F5CA 217.71%);
  background-size: 100%;
  background-repeat: no-repeat;
  border-bottom-left-radius: 60% 18%;
  border-bottom-right-radius: 60% 18%;

}

.img-icono-card {
  width: 36px;
  height: 48px;
  left: 27px;
  margin-top: 25px;
  margin-right: 16px;
  bottom: 22px;

}

.img-icono-card-grid {
  width: 36px;
  height: 48px;

  /* margin-top: 25px; */
  /*  margin-right: 16px; */
  bottom: 22px;
}


.img-icono-card-grid-folder {
  width: 36px;
  height: 48px;
  margin-top: 10px;
  margin-right: 16px;
  bottom: 22px;
}

.img-icono-card-inm {
  width: 50px;
  height: 56px;
  margin-left: -16px !important;
  margin-top: -6px;
  margin-right: 30px;
  bottom: 22px;
}

.container-inm-home-grid {
  width: 300px;
}

.btn-state-home {
  margin-top: 63px;
  margin-left: 4px;
}

.btn-state-home-grid {
  margin-top: 32px;
  margin-left: -8px;

}

.btn-state-home-grid-gris {
  margin-top: 32px;
  margin-left: -8px;

}

.btn-state-home-grid-blue {
  margin-top: 52px;
  margin-left: -8px;
}

.text-inm-home-width {
  margin-top: -10px !important;
  width: 180px !important;
}

.card-top {
  margin-top: 25px;
  margin-bottom: 4px;
}

.card-top-grid {
  width: 100%;
}

.card-home-offer {
  margin-left: 30px;
  margin-right: 0px;
  padding: 0px 0px 0px 8px;
}

.text-white {
  color: var(--color-fondo) !important;

}


.text-white-home {

  /* identical to box height */
  letter-spacing: -0.015em;
  font-family: 'Rustica';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.015em;

  color: #FFFFFF;

}

.text-white-profile {
  color: var(--color-fondo);
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  margin-top: 34px;
  margin-left: -24px;
  width: 300px;
  /* identical to box height */

}

.text-white-email {
  color: var(--color-fondo);
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  margin-top: -16px;
  margin-left: -24px;
  margin-bottom: 0px;
  /* identical to box height */
}

.text-white-custumer {
  color: var(--color-fondo);
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height */


  letter-spacing: -0.015em;
}

.text-name-space {
  margin-top: 10px;
}

.text-orange {


  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  letter-spacing: 0.025em;

  color: #FF864B;

  margin-top: -5px;
}

.img-cerrar {
  width: 40px;
  height: 40px;
  margin-top: 5px;
  margin-right: 15px;
  margin-bottom: 10px;
}

.space-docs-home {
  margin-top: 20px;
}

.text.amp {
  width: 300px;
  height: 15px;
}

.btn-cerrar {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 10px;
  margin-right: -16px;
}

.Container-cards-seccion {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 5px;

  padding: 16px 24px;
  gap: 16px;

  width: 365px;
  height: 120px;
  margin-left: 16px;
  margin-right: 16px;

  border-radius: 5px;
  top: 210px;
  bottom: 100px;

  background-color: var(--color-fondo);
  border: 1px solid #E1EBF4;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;

}

.Container-cards-seccion-m {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 5px;

  padding: 16px 24px;
  gap: 16px;
  height: 120px;
  margin-left: 16px;
  margin-right: 16px;

  border-radius: 5px;
  top: 210px;
  bottom: 100px;

  background-color: var(--color-fondo);
  border: 1px solid #E1EBF4;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;

}

.Container-cards-seccion-mui {

  margin-top: 24px;
  margin-bottom: 5px;

  height: 120px;
  margin-left: 16px;
  margin-right: 16px;
  border-radius: 5px;
  background-color: var(--color-fondo);
  border: 1px solid #E1EBF4;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;

}

.Container-cards-seccion-mui-mensajesalesforce {

  margin-top: 24px;
  margin-bottom: 5px;

  height: 160px;
  margin-left: 16px;
  margin-right: 16px;
  border-radius: 5px;
  background-color: var(--color-fondo);
  border: 1px solid #E1EBF4;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;

}

.borde-card-mui {

  margin-top: 10px;
  margin-left: 18px;
  margin-right: 18px;
  background-color: var(--color-fondo);
  border: 1px solid #E1EBF4;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
}

.borde-card-home-mui {

  margin-top: 10px;

  background-color: var(--color-fondo);
  border: 1px solid #E1EBF4;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
}

.text-mui-home {
  padding: 16px;
}

.text-black {
  color: #000000;
}

.navbar {
  margin-top: 50px;
  border-color: #0A3323;
  border-radius: 0px;
  border: #0A3323;

}

.navbar-container {
  height: 30rem;
}

.navbar-nav {
  margin: 50px 30px 10px 20px;
  color: #0A3323;
}

.nav-section {
  height: 60px;

}

.tarjetasdatos-usuario {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  padding: 16px 24px;
  gap: 16px;
  width: 380px;
  height: 100px;
  left: 25px;
  top: 210px;
  bottom: 100px;

  background-color: var(--color-fondo);
  border: 1px solid #E1EBF4;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
}

.link-style {
  color: #0A3323;
  left: 25px;
  font-size: 14px;
  font-weight: 500;
}

.card-docs {
  display: flex;
  justify-content: space-between;

  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 16px 24px;
  gap: 16px;

  width: 365px;
  height: 80px;
  left: 25px;
  top: 210px;
  bottom: 100px;
  margin-left: 16px;
  margin-right: 16px;
  background-color: var(--color-fondo);
  border: 1px solid #E1EBF4;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;

}

.card-docs-m {
  display: flex;
  justify-content: center;
  ;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 5px;

  padding: 16px 24px;
  gap: 16px;
  height: 80px;
  margin-left: 16px;
  margin-right: 16px;

  top: 210px;
  bottom: 100px;

  background-color: var(--color-fondo);
  border: 1px solid #E1EBF4;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
}

.card-docs-mui-home {


  flex-direction: row;
  align-items: center;

  margin-top: 24px;
  margin-bottom: 5px;
  width: 100% !important;

  height: 80px;


  background-color: var(--color-fondo);
  border: 1px solid #E1EBF4;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
}

.card-docs-grid-mui {

  width: 90%;
  margin-top: 24px;
  margin-bottom: 5px;

  padding: 16px 16px 16px 16px;


  background-color: var(--color-fondo);
  border: 1px solid #E1EBF4;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
}

.btn-m {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 5px;

  padding: 16px 24px;
  gap: 16px;
  height: 80px;
  margin-left: 16px;
  margin-right: 16px;

  top: 210px;
  bottom: 100px;

}

.card-docs-agenda {

  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 16px 24px;
  gap: 16px;
  width: 390px;
  height: 80px;
  left: 25px;
  top: 210px;
  bottom: 100px;
  background: #FFFFFF;
  border: 1.4px solid #D0D0D0;
  border-radius: 10px;
}

.arrow-menu {
  width: 24px;
  height: 24px;
}

.arrow-menu-home-mui {
  width: 24px;
  height: 24px;
  margin-top: 16px;
  margin-left: 10px;
}


.img-estados {
  width: 358px;
  height: 257px;
  margin-left: 16px;
  margin-right: 16px;
}

a {
  text-decoration: none;
}

.btn-prueba {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px 0px;
  gap: 8px;
  width: 365px;
  height: 58px;
  left: 24px;
  margin-top: 30px;
  margin-bottom: 5px;
  background: linear-gradient(0deg, #81A1F8, #81A1F8), #81A1F8;
  /* M3/Elevation Light/1 */

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.btn-prueba-blanco {
  width: 365px;
  height: 58px;
  margin-top: 8px;
  margin-bottom: 20px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), #FFFFFF;
  border: 1px solid #6C9FFF;
  /* M3/Elevation Light/1 */

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.btn-prueba-blanco-grid {
  width: 365px;
  height: 58px;
  margin-top: 8px;
  margin-bottom: 20px;
}

.btn-prueba-ingreso {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px 0px;
  gap: 8px;
  width: 330px;
  height: 58px;
  left: 24px;
  margin-top: 30px;
  margin-bottom: 5px;
  background: linear-gradient(0deg, #81A1F8, #81A1F8), #81A1F8;
  /* M3/Elevation Light/1 */

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.centrado {
  display: flex;
  justify-content: center;
  align-items: center;
}

.centrado-mensaje {
  margin-left: 40px;
  margin-right: 40px;
  width: 80%;
}

.rounded-start {
  width: 58px;
  height: 58px;
  border-radius: 100%;
}

.img-data-perfil {
  width: 48px;
  height: 48px;
  margin-top: 18px;
  margin-left: 16px;
  margin-bottom: 18px;

}

.text-center {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
}

.text-docs {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  text-align: start;
}

.text-reu {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  text-align: start;
  margin-top: 1px;
}

.text-blue {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  color: var(--color-primario-btn);
}

.danger-text {
  color: var(--color-naranja-duppla);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.text-btnloggout {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 15px 0px;
  top: -50px;
}

.text-space {
  margin-top: 15px;
  padding: 0px 0px 0px 0px;
}

/*Estilos componenete inmueble-property*/

.card-inmueble {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  padding: 16px 24px;
  gap: 16px;
  width: 390px;
  height: 180px;
  left: 25px;
  right: 25px;
  top: 210px;
  bottom: 100px;
}

.card-inmueble-first {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  padding: 16px 24px;
  gap: 16px;
  width: 390px;
  height: 180px;
  left: 25px;
  right: 25px;
  top: 210px;
  bottom: 100px;
}

.card-inm-value {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  padding: 16px 24px;
  gap: 16px;
  width: 390px;
  height: 180px;
  left: 25px;
  right: 25px;
  margin-top: 80px;
  bottom: 100px;
}

.card-body {
  padding-bottom: 5px;
  width: 280px;
  margin-left: -5px;
}

.card-body-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-body-docs {
  padding-bottom: 5px;

}

.card-perfil-datos {
  margin-top: -10px;
  margin-bottom: 15px;
}

.description-apt {
  margin: 16px 16px 16px 16px;
}

.btn-d-cancel {
  box-sizing: border-box;
  position: relative;
  width: 160px;
  height: 52px;
  margin-left: 10px;
  top: 24px;
  background: #81A1F8;
  color: var(--color-fondo);
  /* Neutral / Grey 800 */

  border: 0.5px solid #323F4B;
  border-radius: 10px;
}

.btn-d-aceptar {
  box-sizing: border-box;

  position: relative;
  width: 160px;
  height: 52px;
  top: 24px;
  margin-right: 10px;
  background: var(--color-fondo);
  color: #323F4B;
  /* Neutral / Grey 800 */
  border: 1px solid #323F4B;
  border-radius: 10px;
}

.space-btn-property {
  margin-top: 34px;
  margin-bottom: 24px;
}

.card-state-properties {
  display: flex;
  justify-content: flex-start;
  align-items: start;
  height: 20px;
  width: 360px;
  margin-bottom: 6px;
}

.card-state-properties-home {
  display: flex;
  justify-content: flex-start;
  align-items: start;
  gap: 16px;
  width: 380px;
  margin-bottom: 6px;
  margin-top: 5px;
  margin-left: 30px;
}

.nav-item {
  border-top: 1px solid #f3f4f6;
  border-bottom: 1px solid #f3f4f6;
  border-left: 1px solid #ffffff;
  border-right: 1px solid #ffffff;
}

.img-navbar-home {
  display: flex;
  justify-content: center;
  align-items: center;

}

.img-navbar {
  width: 50px;
  height: 5px;
}

.text-navbar-options {
  margin: 12px 10px -10px 30px;
}

.img-state-propety {
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
}

.dropdown-menu-cambio {
  width: 280px;
  height: 230px;
  margin-bottom: 10px;

  background: #F9F9F9;
  border-radius: 10px;
}

.list-group-item {
  width: 260px;
  height: 30px;
  background: #F9F9F9;
  border: #F9F9F9;
}

.space-text-span {
  margin-bottom: -20px;
}

.space-property-evaluation {
  margin-bottom: 20px;
}

/*Sección de oferta*/
.container-offer {
  height: 900px !important;
}

.img-offer-conatiner {
  align-items: center;
  width: 360px;
  height: 350px;

  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 50px;

}

.offer-container-link {
  margin-top: 30px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 60px;
}

.text-offer-link {
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  color: var(--color-cimiento-duppla);
}

.text-name-home {

  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height */
  margin-top: 34px !important;
  margin-bottom: 1px;
  width: 200px;

  display: flex;
  align-items: center;
  letter-spacing: -0.015em;

  color: #FFFFFF;
}

.icon-navbar-home {
  margin-bottom: 29px;
  margin-left: -12px;
}

.navbar-move {
  height: 500px !important;
  border-radius: 12px 12px 0px 0px;
}

/*Responsive*/


@media screen and (min-width: 270px) {

  .container-inm-home-grid {
    width: 300px !important;
  }

  .card-body-form {

    margin-bottom: 31px;
    width: 260px;
    height: auto;
    background-color: #ffffff;
    border: 1px solid #ced4da;
    padding: 1.25rem !important;
    background-clip: padding-box;
    border-radius: 10px;

  }

  .Container-cards-payment-customer-mui {

    width: 100% !important;
    margin-bottom: 5px;
    height: 90px;
    background-color: var(--color-fondo);
    border: 1px solid #E1EBF4;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 12px;

  }

  .cards-payment-jsx-mui {
    width: 100% !important;

  }

  .text-label-form-payment {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    color: var(--color-cimiento-duppla);
   
  }

}

@media screen and (min-width: 360px) {

  .btn-prueba-blanco-grid {
    width: 300px;
    height: 58px;
    margin-top: 8px;
    margin-bottom: 20px
  }

  .btn-prueba-blanco {
    width: 300px;
    height: 58px;
    margin-top: 8px;
    margin-bottom: 20px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), #FFFFFF;
    border: 1px solid #6C9FFF;
    /* M3/Elevation Light/1 */
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    border-radius: 10px;

  }

  .btn-prueba {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 6px 0px;
    gap: 8px;
    width: 300px;
    height: 58px;
    left: 24px;
    margin-top: 30px;
    margin-bottom: 5px;
    background: linear-gradient(0deg, #81A1F8, #81A1F8), #81A1F8;
    /* M3/Elevation Light/1 */

    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
  }

  .btn-prueba-ingreso {

    gap: 8px;
    width: 320px;
    height: 58px;
    left: 24px;
    margin-top: 30px;
    margin-bottom: 5px;
    background: linear-gradient(0deg, #81A1F8, #81A1F8), #81A1F8;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
  }

  .input-register {

    width: 320px;
    height: 52px;
    margin-top: 10px;
    margin-bottom: 10px;

  }

  hr {
    /*  position: absolute; */

    width: 66px;
    height: 0px;
    left: 80px;
    top: 67px;
    margin-top: 58px;
    margin-left: 1px;
    margin-bottom: 35px;
    fill: #ffffff;

    border: 1px solid #ffffff !important;
    transform: rotate(90deg);
    background-color: #ffffff !important;

  }

  .vertical-line {
    position: absolute;
    margin-left: 114px;
    margin-top: -62px;
    margin-bottom: 36px;
    width: 1px;
    height: 61px;
    background-color: rgb(255, 255, 255);
    border: 1px solid #E7E7E7;

  }

  .img-duppla {
    position: absolute;
    padding: 0px;
    width: 80px;
    height: 28px;
    margin-left: 20px;
    margin-top: 2px;
    margin-bottom: 10px;
  }

  .text-space-inm-dropdown {
    margin-top: 13px;
    margin-left: 35px;
    width: 60px;
  }

  .card-dropdown-inm {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -8px;
    margin-bottom: 5px;

    padding: 16px 24px;
    gap: 16px;
    width: 260px;
    height: 58px;
    margin-left: -8px;
    margin-right: 10px;
    border-radius: 12px;

    background: #ffffff;
  }

  .bell {

    height: 24px;
    width: 24px;
    margin-left: 140px;
    margin-right: 30px;
    margin-top: -46px;


  }

  .bell-mui {
    height: 24px;
    width: 24px;
    margin-left: 10px;
    margin-top: -46px;
    color: #FFFFFF;
    fill: #FFFFFF;

  }

  .btn-pago-custumer {
    width: 344px;
    height: 58px;
    margin-top: 26px;
    margin-bottom: 26px;
    background: linear-gradient(0deg, #81A1F8, #81A1F8), #81A1F8;
    border: 1px solid #81A1F8;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
  }

  .card-body-form-two {

    display: grid;
    grid-template-columns: 1fr;
    margin-left: 28px;
    margin-right: 28px;
    grid-gap: 10px;
    margin-bottom: 31px;
    /*  width: 320px; */
    height: auto;
    background-color: #ffffff;
    border: 1px solid #ced4da;
    padding: 1.25rem !important;
    background-clip: padding-box;
    border-radius: 10px;

  }

  .imgs-navbar-mui {
    margin-left: 24px !important;
  }

  .separacion-text-mui {
    margin-top: -6px;
  }

  .container-inm-home-grid {
    width: 310px !important;
  }

  .text-white-profile {
    color: var(--color-fondo);
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    margin-top: 24px;
    margin-left: -24px;
    width: 200px;
  }

  .card-body-form {

    margin-bottom: 31px;
    width: 320px;
    height: auto;
    background-color: #ffffff;
    border: 1px solid #ced4da;
    padding: 1.25rem !important;
    background-clip: padding-box;
    border-radius: 10px;

  }

  .Container-cards-payment-customer-mui {

    width: 100% !important;
    /*   margin-top: 24px; */
    margin-bottom: 5px;
    height: 90px;
    background-color: var(--color-fondo);
    border: 1px solid #E1EBF4;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 12px;

  }

  .Container-cards-payment-bar {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 24px;
    margin-bottom: 5px;

    width: 100%;

    padding: 16px 24px;
    gap: 16px;
    height: 270px;
    margin-left: 16px;
    margin-right: 16px;

    border: 1px solid #E1EBF4;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 12px;

  }

  .text-name-home {

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height */
    margin-top: 34px !important;
    margin-bottom: 1px;
    width: 200px;

    display: flex;
    align-items: center;
    letter-spacing: -0.015em;

    color: #FFFFFF;
  }

  .tooltip-payment-mui {
    position: absolute;
    margin-top: -44px;
    margin-left: 120px;
    width: 200px;
    transform: translateX(-50%);
    background-color: #ffffff;
    color: #0A3323;
    padding: 8px;
    border-radius: 4px;
    font-size: 14px;
    opacity: 1;
    box-shadow: 0px 0px 10px rgba(115, 113, 113, 0.15);
  }

  .margin-box-mui-payment {
    margin-left: 32px !important;
  }

  .loanding {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    /*  background-color: #F1FFEB; */
    border-radius: 10px;
  }

  .loanding-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
  }

  .text-loandig {
    color: #0A3323;
    margin-bottom: 20px;
  }

  .loanding-state-mui {
    width: 140px;
    height: 140px;
  }

  .video-docs {
    height: 300px;
    width: 300px;

  }

  .video-docs-text-date {
    height: auto;
    width: 300px;
  }

  .cards-payment-jsx-mui {
    width: 356px !important;

  }

  .text-label-form-payment {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    color: var(--color-cimiento-duppla);
   
  }

}

@media screen and (min-width: 576px) {

  .btn-prueba {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 6px 0px;
    gap: 8px;
    width: 430px;
    height: 58px;
    left: 24px;
    margin-top: 30px;
    margin-bottom: 5px;
    background: linear-gradient(0deg, #81A1F8, #81A1F8), #81A1F8;
    /* M3/Elevation Light/1 */

    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
  }

  .btn-prueba-blanco {
    width: 430px;
    height: 58px;
    margin-top: 8px;
    margin-bottom: 20px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), #FFFFFF;
    border: 1px solid #6C9FFF;
    /* M3/Elevation Light/1 */

    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    border-radius: 10px;

  }

  .btn-prueba-ingreso {

    gap: 8px;
    width: 430px;
    height: 58px;
    padding: 0px 0px 0px 0px;
    margin-top: 30px;
    margin-bottom: 5px;
    margin-left: 10px;
    margin-right: 10px;
    background: linear-gradient(0deg, #81A1F8, #81A1F8), #81A1F8;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
  }

  .img-duppla {
    position: absolute;
    padding: 0px;
    width: 80px;
    height: 28px;
    margin-left: 30px;
    margin-top: 2px;
    margin-bottom: 10px;
  }

  .input-register {

    width: 400px;
    height: 52px;
    margin-top: 10px;
    margin-bottom: 10px;

  }

  .bell {

    height: 24px;
    width: 24px;
    margin-left: 120px;
    margin-right: 30px;
    margin-top: -46px;

  }

  .container-inm-home-grid {
    width: 300px !important;
  }

  .card-body-form {

    margin-bottom: 31px;
    width: 340px;
    height: auto;
    background-color: #ffffff;
    border: 1px solid #ced4da;
    padding: 1.25rem !important;
    background-clip: padding-box;
    border-radius: 10px;

  }

  .Container-cards-payment-customer-mui {

    width: 100% !important;
    margin-top: 24px;
    margin-bottom: 5px;

    height: 90px;
    background-color: var(--color-fondo);
    border: 1px solid #E1EBF4;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 12px;

  }

  .text-name-home {

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height */
    margin-top: 34px !important;
    margin-bottom: 1px;
    width: 200px;

    display: flex;
    align-items: center;
    letter-spacing: -0.015em;

    color: #FFFFFF;
  }

  .margin-box-mui-payment {

    margin-left: 3px !important;

  }

  .video-docs {
    height: 360px;
    width: 400px;

  }

  .video-docs-text-date {
    height: auto;
    width: 1040px;
  }

  .cards-payment-jsx-mui {
    width: 460px !important;
    height: 90px;
  }

  .text-label-form-payment {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400;
    color: var(--color-cimiento-duppla);
   
  }

}

/*Dispositivos medianos (tabletas, 768px y más)*/
@media screen and (min-width: 768px) {
  .container-inm-home-grid {
    width: 400px;
  }

  .Container-cards-payment-customer-mui {

    width: 600% !important;
    margin-bottom: 5px;
    height: 90px;
    background-color: var(--color-fondo);
    border: 1px solid #E1EBF4;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 12px;

  }

  .margin-box-mui-payment {

    margin-left: 2px !important;

  }

  .loanding-state-mui {
    width: 200px;
    height: 200px;
  }

  .video-docs {
    height: 360px;
    width: 640px;

  }

  .video-docs-text-date {
    height: auto;
    width: 640px;
  }

  .cards-payment-jsx-mui {
    width: 480px !important;

  }

  .text-label-form-payment {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400;
    color: var(--color-cimiento-duppla);

  }
}

/* Dispositivos grandes (escritorios, 992px y más)*/
@media screen and (min-width: 992px) {
  .container-inm-home-grid {
    width: 400px;
  }

  .card-body-form {

    margin-bottom: 31px;
    width: 100%;
    height: auto;
    background-color: #ffffff;
    border: 1px solid #ced4da;
    padding: 1.25rem !important;
    background-clip: padding-box;
    border-radius: 10px;

  }

  .Container-cards-payment-customer-mui {

    width: 100% !important;
    margin-top: 24px;
    margin-bottom: 5px;
    padding: 16px 24px;
    height: 90px;
    background-color: var(--color-fondo);
    border: 1px solid #E1EBF4;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 12px;

  }

  .text-name-home {

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height */
    margin-top: 34px !important;
    margin-bottom: 1px;
    width: 280px;

    display: flex;
    align-items: center;
    letter-spacing: -0.015em;

    color: #FFFFFF;
  }

  .tooltip-payment-mui {
    position: absolute;
    margin-top: -40px;
    margin-left: 140px;
    width: 230px;
    transform: translateX(-50%);
    background-color: #ffffff;
    color: #0A3323;
    padding: 8px;
    border-radius: 4px;
    font-size: 14px;
    opacity: 1;
    box-shadow: 0px 0px 10px rgba(115, 113, 113, 0.15);
  }

  .margin-box-mui-payment {
    margin-left: 5px !important;
  }

  .loanding-state-mui {
    width: 160px;
    height: 160px;
  }

  .cards-payment-jsx-mui {
    width: 374px !important;

  }

  .text-label-form-payment {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400;
    color: var(--color-cimiento-duppla);

  }
}

/* Dispositivos X-Large (escritorios grandes, 1200px y más)*/
@media (min-width: 1200px) {
  .bell-mui {
    height: 24px;
    width: 24px;
    margin-left: -10px;
    margin-top: -46px;
    color: #FFFFFF;
    fill: #FFFFFF;

  }

  .container-inm-home-grid {
    width: 400px !important;
  }

  .text-white-profile {
    color: var(--color-fondo);
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    margin-top: 34px;
    margin-left: -24px;
    width: 300px;
    /* identical to box height */

  }

  .card-body-form {

    margin-bottom: 31px;
    width: 100%;
    height: auto;
    background-color: #ffffff;
    border: 1px solid #ced4da;
    border-radius: 10px;

  }

  .Container-cards-payment-customer-mui {

    width: 100% !important;
    margin-top: 24px;
    margin-bottom: 5px;
    padding: 16px 24px;
    height: 90px;
    background-color: var(--color-fondo);
    border: 1px solid #E1EBF4;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 12px;

  }

  .Container-cards-payment-bar {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 24px;
    margin-bottom: 5px;
    width: 84%;
    padding: 16px 24px;
    gap: 16px;
    height: 270px;
    margin-left: 16px;
    margin-right: 16px;
    border: 1px solid #E1EBF4;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 12px;

  }

  .text-name-home {

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height */
    margin-top: 34px !important;
    margin-bottom: 1px;
    width: 320px;

    display: flex;
    align-items: center;
    letter-spacing: -0.015em;

    color: #FFFFFF;
  }

  .tooltip-payment-mui {
    position: absolute;
    margin-top: -40px;
    margin-left: 160px;
    width: 280px;
    transform: translateX(-50%);
    background-color: #ffffff;
    color: #0A3323;
    padding: 8px;
    border-radius: 4px;
    font-size: 14px;
    opacity: 1;
    box-shadow: 0px 0px 10px rgba(115, 113, 113, 0.15);
  }

  .margin-box-mui-payment {

    margin-left: 1px !important;

  }

  .loanding-state-mui {
    width: 160px;
    height: 160px;
  }

  .video-docs {
    height: 560px;
    width: 1040px;

  }

  .video-docs-text-date {
    height: auto;
    width: 1040px;
  }

  .cards-payment-jsx-mui {
    width: 374px !important;

  }

  .text-label-form-payment {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400;
    color: var(--color-cimiento-duppla);

  }
}

/* Dispositivos XX-Large (escritorios más grandes, 1400px y más)*/
@media (min-width: 1600px) {
  .bell-mui {
    height: 24px;
    width: 24px;
    margin-left: -10px;
    margin-top: -46px;
    color: #FFFFFF;
    fill: #FFFFFF;

  }

  .container-inm-home-grid {
    width: 400px !important;
  }

  .btn-prueba-blanco-grid {
    width: 430px;
    height: 58px;
    margin-top: 8px;
    margin-bottom: 20px;
  }

  .text-white-profile {
    color: var(--color-fondo);
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    margin-top: 34px;
    margin-left: -24px;
    width: 300px;
  }
  .card-body-form {
    margin-bottom: 31px;
    width: 100%;
    height: auto;
    background-color: #ffffff;
    border: 1px solid #ced4da;
    padding: 1.25rem !important;
    background-clip: padding-box;
    border-radius: 10px;
  }

  .Container-cards-payment-customer-mui {


    width: 100% !important;
    margin-top: 24px;
    margin-bottom: 5px;
    padding: 16px 24px;
    height: 90px;
    background-color: var(--color-fondo);
    border: 1px solid #E1EBF4;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 12px;

  }

  .Container-cards-payment-bar {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 24px;
    margin-bottom: 5px;

    width: 84%;

    padding: 16px 24px;
    gap: 16px;
    height: 270px;
    margin-left: 16px;
    margin-right: 16px;

    border: 1px solid #E1EBF4;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 12px;

  }

  .text-name-home {

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height */
    margin-top: 34px !important;
    margin-bottom: 1px;
    width: 320px;

    display: flex;
    align-items: center;
    letter-spacing: -0.015em;
    color: #FFFFFF;
  }

  .margin-box-mui-payment {
    margin-left: 4px !important;
  }

  .loanding-state-mui {
    margin-top: 24px !important;
    width: 160px;
    height: 160px;
  }


  .video-docs {
    height: 560px;
    width: 1040px;

  }

  .video-docs-text-date {
    height: auto;
    width: 1040px;
  }

  .cards-payment-jsx-mui {
    width: 374px !important;

  }
  .text-label-form-payment {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400;
    color: var(--color-cimiento-duppla);
   
  }


};