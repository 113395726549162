code {
    font-family: Arial, Helvetica, sans-serif;
}

/* variables de colores duppla*/
:root {
    --color-primario-btn: #81A1F8;
    --color-fondo: #FFFFFF;
    --color-naranja-duppla: #FF864B;
    --color-verde-duppla: #C5F5CA;
    --color-cimiento-duppla: #0A3323;
    --color-sombra-duppla: #EEEFF3;
    --color-fondo-gris: #F9F9F9;
}

.container-notice-date{
    padding: 14px 0px 0px 0px;
    background: var(--color-verde-duppla);
    height: 240px;
}

.text-notice-date {
    margin-top: 20px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
}

.text-notice{
    margin-top: 20px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    
}

.text-notice-two{
    margin-top: -8px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
}

.date-history{
   
    margin-left: 15px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
}

/*Sección pago con demora */
.container-notice-date-two{
    padding: 14px 0px 0px 0px;
    background: var(--color-primario-btn);
    height: auto;
}

.text-notice-date-two {
    margin-top: 20px;
    color: #FFFFFF;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    border-radius: 0 0 20px 20px;
    min-width: 340px!important;
}

.text-notice-second {
    margin-top: 20px;    
    color: #FFFFFF;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    
}

.text-notice-second-s{
    margin-top: -8px;
    color: #FFFFFF;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
}



.container-notice-date-three{
    padding: 14px 0px 0px 0px;
    background: var(--color-naranja-duppla);
    height: 240px;
}