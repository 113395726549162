html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden !important;
  width: 100% !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Rustica', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Rustica'
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Arial, Helvetica, sans-serif;
}

/* variables de colores duppla*/
:root {
  --color-primario-btn: #6C9FFF;
  --color-fondo: #FFFFFF;
  --color-naranja-duppla: #FF864B;
  --color-verde-duppla: #C5F5CA;
  --color-cimiento-duppla: #0A3323;
  --color-sombra-duppla: #EEEFF3;
  --color-fondo-gris: #F9F9F9;
}

@font-face {
  font-family: 'Rustica';
  src: url(/static/media/RusticaMedium.ed65affe.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Rustica';
  src: url(/static/media/RusticaLight.37f40ff1.ttf) format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Rustica';
  src: url(/static/media/RusticaRegular.dcda657e.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Rustica';
  src: url(/static/media/RusticaRegular.dcda657e.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNowText-Light';
  src: url(/static/media/HelveticaNowTextLight.226a5339.otf) format('opentype');
  font-weight: 200;
  font-style: normal;
}

/*Configuración container*/
.container-fluid {
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
}

.container {
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
}

.container-sm {
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
}

.container-md {
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
}

/*Estilos para el componente sing in*/

.btn-registro {
  color: #FFFFFF;
  color: var(--color-fondo);
  font-family: Arial, Helvetica, sans-serif;
}

.b-color-gris {
  color: #F9F9F9;
  color: var(--color-fondo-gris)
}

.card-title {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
}

.card-title-home {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
}

.card-title-home-mui {
  margin-top: 20px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
}

.card-text {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  margin-top: 25px;
}

.card-text-grid {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  margin-top: 5px;
  width: 100%;
}

.card-text-grid-prc {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  margin-top: 4px;
  width: 100%;
}

.card-text-docs {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  margin-top: 4px;
}

.card-text-aprov {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  margin-top: 4px;
}

.card-text-aprov-grid {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  margin-top: -11px;
}

.container-sing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 120px;
  padding: 10px;

  height: auto;
}

.img-logotipo {
  width: 327px;
  height: 269px;
  left: 24px;
  top: 180px;

}

.btn {
  border-radius: 5px;

}

.btn-registro {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 315px;
  height: 46px;
  border-radius: 10px;
  box-shadow: 0 5 15 0 #EEEFF3;
  box-shadow: 0 5 15 0 var(--color-sombra-duppla);
  margin-top: 50px;
  margin-bottom: 16px;
  background: #6C9FFF;
  background: var(--color-primario-btn);

}

.centrado-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 315px;
  height: 46px;
  margin-bottom: 50px;
}

.btn-center-home {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-ingreso-google {
  background-color: #FFFFFF;
  margin-top: 16px;
  margin-bottom: 16px;
  border-radius: 10px;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.16);
}

.btn-ingreso-facebook {
  background-color: #ffffff;
  ;
  margin-bottom: 16px;
  border-radius: 10px;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.16);

}

.img-ingreso {
  padding: 5px;
  margin-right: 18px;
}

.link-btn {
  text-decoration: none;
}

.btn-decoration {
  text-decoration: none;
}

/*Estilos registro email y contraseña*/

.register {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.container-register {

  width: 420px;
  height: auto;
  margin-top: 30px;
  margin-left: 10px;
  margin-right: 10px;

}

.arrow-return {
  margin-top: 10px;
  text-decoration: none;
  color: #000000;
}

.title-register {
  padding: 0 0 5px 5px;
  margin-top: 20px;
  text-align: start;
  margin-bottom: 20px;
  margin-left: 15px;
  color: #0A3323;

}

.element-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Alinear elementos en el centro horizontalmente */
  width: 100%;
  /* Establecer el ancho del contenedor */
  max-width: 800px;
  /* Puedes ajustar este valor según tus necesidades */
  margin: 0 auto;
  /* Centrar el contenedor en la página */
}

.title-login {
  padding: 0 0 5px 5px;
  margin-top: 48px;
  text-align: start;
  margin-bottom: -20px;


  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 31px;
  display: flex;

  letter-spacing: -0.015em;
  color: #0A3323
}

.MuiTooltip-tooltip {
  background-color: #95B1FF !important;
  /* Color del fondo del contenido */
  color: white !important;
  /* Color del texto dentro del tooltip */
}

.form-register {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
}

.btn-mover {
  margin-left: 8px;
}

.input-register {
  width: 352px;
  height: 52px;
  margin-top: 10px;
  margin-bottom: 10px;

}

/*Estilos perfil*/
.container-profile {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  background: #F5F5F5;
  height: 1200px;

}

.profile-data {
  display: flex;
  flex-direction: initial;
  justify-content: space-between;
  align-items: center;
  height: 110px;
  left: 14px;
  top: 70px;
  bottom: 100px;
  background: linear-gradient(138.67deg, #0A3323 16.71%, #C5F5CA 162.89%);
  border-radius: 5px;
}


/*Manejo img de perfil y home*/
.img-fluid {
  width: 358px;
  height: 170px;
  margin-left: 16px;
  margin-right: 16px;

}

/*Manejo img de perfil y home*/
.img-duppla-naranja {
  width: 80px;
  height: 30px;
}

.img-user {
  width: 64px;
  height: 63px;
  margin-left: 32px;
  margin-top: 25px;
}

.img-user-mui {
  width: 64px;
  height: 63px;

}

.img-user-custumer {
  width: 64px;
  height: 63px;
  margin-left: 32px;
  margin-top: -20px;
}

.img-user-img {
  width: 64px;
  height: 63px;
  margin-top: 30px;
  margin-left: 16px;
  margin-bottom: 10px;
}

.contenedor-img-duppla {
  width: 80px;
  height: 30px;
}

.img-duppla {


  width: 80px;
  height: 28px;
  left: 278px;
  top: 20px;
  margin-left: 20px;
  margin-right: 24px;
}

.card-home {
  margin-top: 75px;
}

.img-state-progress {
  width: 32px;
  height: 32px;
  margin-top: 18px;
  margin-left: 16px;
  margin-bottom: 18px;

}

.text-state-progress {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
}

.text-profile-mui {
  margin-top: 24px;
}

.user-data-card {

  background-color: #FFFFFF;

  background-color: var(--color-fondo);
  height: 100%;


  background: #FFFFFF;
  box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
}

.size-text-card-profile-mui {
  margin-top: 10px;
}

/*sección datos en perfil*/

.card-perfil {
  margin-top: 27px;
  margin-bottom: 30px;
}

.links {
  text-decoration: none;
}

/* linea en perfil |*/
/* hr {

  width: 10px;
  height: 0px;
  left: 100px;
  top: 65px;
  margin-top: 30px;
  margin-left: 10px;
  border: 1px solid #FFFFFF !important;
  transform: rotate(90deg);
  background-color: #ffffff !important;


} */

.more {
  width: 110px;


}

.link-styles {

  color: #FFFFFF;

  color: var(--color-fondo);
  left: 25px;
  font-size: 14px;
  font-weight: 500;
}


/*Estilos inicio o home aplicación*/


/* tarjeta perfil home*/
.profile {
  display: flex;
  flex-direction: initial;
  justify-content: space-between;
  align-items: center;

  height: 177px;
  left: 0px;
  top: 0px;
  bottom: 100px;
  padding: 0px 0px 0px 0px;
  margin-bottom: 31px;

  background: linear-gradient(60.01deg, #0A3323 19.88%, #C5F5CA 217.71%);
  background-size: 100%;
  background-repeat: no-repeat;
  border-bottom-left-radius: 60% 18%;
  border-bottom-right-radius: 60% 18%;

}

.profile-custumer {
  position: relative;
  display: grid;
  height: 177px;
  padding: 0px 0px 0px 0px;
  margin-bottom: 31px;
  background: linear-gradient(60.01deg, #0A3323 19.88%, #C5F5CA 217.71%);
  background-size: 100%;
  background-repeat: no-repeat;
  border-bottom-left-radius: 60% 18%;
  border-bottom-right-radius: 60% 18%;

}

.img-icono-card {
  width: 36px;
  height: 48px;
  left: 27px;
  margin-top: 25px;
  margin-right: 16px;
  bottom: 22px;

}

.img-icono-card-grid {
  width: 36px;
  height: 48px;

  /* margin-top: 25px; */
  /*  margin-right: 16px; */
  bottom: 22px;
}


.img-icono-card-grid-folder {
  width: 36px;
  height: 48px;
  margin-top: 10px;
  margin-right: 16px;
  bottom: 22px;
}

.img-icono-card-inm {
  width: 50px;
  height: 56px;
  margin-left: -16px !important;
  margin-top: -6px;
  margin-right: 30px;
  bottom: 22px;
}

.container-inm-home-grid {
  width: 300px;
}

.btn-state-home {
  margin-top: 63px;
  margin-left: 4px;
}

.btn-state-home-grid {
  margin-top: 32px;
  margin-left: -8px;

}

.btn-state-home-grid-gris {
  margin-top: 32px;
  margin-left: -8px;

}

.btn-state-home-grid-blue {
  margin-top: 52px;
  margin-left: -8px;
}

.text-inm-home-width {
  margin-top: -10px !important;
  width: 180px !important;
}

.card-top {
  margin-top: 25px;
  margin-bottom: 4px;
}

.card-top-grid {
  width: 100%;
}

.card-home-offer {
  margin-left: 30px;
  margin-right: 0px;
  padding: 0px 0px 0px 8px;
}

.text-white {
  color: #FFFFFF !important;
  color: var(--color-fondo) !important;

}


.text-white-home {

  /* identical to box height */
  letter-spacing: -0.015em;
  font-family: 'Rustica';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.015em;

  color: #FFFFFF;

}

.text-white-profile {
  color: #FFFFFF;
  color: var(--color-fondo);
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  margin-top: 34px;
  margin-left: -24px;
  width: 300px;
  /* identical to box height */

}

.text-white-email {
  color: #FFFFFF;
  color: var(--color-fondo);
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  margin-top: -16px;
  margin-left: -24px;
  margin-bottom: 0px;
  /* identical to box height */
}

.text-white-custumer {
  color: #FFFFFF;
  color: var(--color-fondo);
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height */


  letter-spacing: -0.015em;
}

.text-name-space {
  margin-top: 10px;
}

.text-orange {


  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  letter-spacing: 0.025em;

  color: #FF864B;

  margin-top: -5px;
}

.img-cerrar {
  width: 40px;
  height: 40px;
  margin-top: 5px;
  margin-right: 15px;
  margin-bottom: 10px;
}

.space-docs-home {
  margin-top: 20px;
}

.text.amp {
  width: 300px;
  height: 15px;
}

.btn-cerrar {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 10px;
  margin-right: -16px;
}

.Container-cards-seccion {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 5px;

  padding: 16px 24px;
  grid-gap: 16px;
  gap: 16px;

  width: 365px;
  height: 120px;
  margin-left: 16px;
  margin-right: 16px;

  border-radius: 5px;
  top: 210px;
  bottom: 100px;

  background-color: #FFFFFF;

  background-color: var(--color-fondo);
  border: 1px solid #E1EBF4;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;

}

.Container-cards-seccion-m {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 5px;

  padding: 16px 24px;
  grid-gap: 16px;
  gap: 16px;
  height: 120px;
  margin-left: 16px;
  margin-right: 16px;

  border-radius: 5px;
  top: 210px;
  bottom: 100px;

  background-color: #FFFFFF;

  background-color: var(--color-fondo);
  border: 1px solid #E1EBF4;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;

}

.Container-cards-seccion-mui {

  margin-top: 24px;
  margin-bottom: 5px;

  height: 120px;
  margin-left: 16px;
  margin-right: 16px;
  border-radius: 5px;
  background-color: #FFFFFF;
  background-color: var(--color-fondo);
  border: 1px solid #E1EBF4;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;

}

.Container-cards-seccion-mui-mensajesalesforce {

  margin-top: 24px;
  margin-bottom: 5px;

  height: 160px;
  margin-left: 16px;
  margin-right: 16px;
  border-radius: 5px;
  background-color: #FFFFFF;
  background-color: var(--color-fondo);
  border: 1px solid #E1EBF4;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;

}

.borde-card-mui {

  margin-top: 10px;
  margin-left: 18px;
  margin-right: 18px;
  background-color: #FFFFFF;
  background-color: var(--color-fondo);
  border: 1px solid #E1EBF4;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
}

.borde-card-home-mui {

  margin-top: 10px;

  background-color: #FFFFFF;

  background-color: var(--color-fondo);
  border: 1px solid #E1EBF4;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
}

.text-mui-home {
  padding: 16px;
}

.text-black {
  color: #000000;
}

.navbar {
  margin-top: 50px;
  border-color: #0A3323;
  border-radius: 0px;
  border: #0A3323;

}

.navbar-container {
  height: 30rem;
}

.navbar-nav {
  margin: 50px 30px 10px 20px;
  color: #0A3323;
}

.nav-section {
  height: 60px;

}

.tarjetasdatos-usuario {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  padding: 16px 24px;
  grid-gap: 16px;
  gap: 16px;
  width: 380px;
  height: 100px;
  left: 25px;
  top: 210px;
  bottom: 100px;

  background-color: #FFFFFF;

  background-color: var(--color-fondo);
  border: 1px solid #E1EBF4;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
}

.link-style {
  color: #0A3323;
  left: 25px;
  font-size: 14px;
  font-weight: 500;
}

.card-docs {
  display: flex;
  justify-content: space-between;

  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 16px 24px;
  grid-gap: 16px;
  gap: 16px;

  width: 365px;
  height: 80px;
  left: 25px;
  top: 210px;
  bottom: 100px;
  margin-left: 16px;
  margin-right: 16px;
  background-color: #FFFFFF;
  background-color: var(--color-fondo);
  border: 1px solid #E1EBF4;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;

}

.card-docs-m {
  display: flex;
  justify-content: center;
  ;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 5px;

  padding: 16px 24px;
  grid-gap: 16px;
  gap: 16px;
  height: 80px;
  margin-left: 16px;
  margin-right: 16px;

  top: 210px;
  bottom: 100px;

  background-color: #FFFFFF;

  background-color: var(--color-fondo);
  border: 1px solid #E1EBF4;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
}

.card-docs-mui-home {


  flex-direction: row;
  align-items: center;

  margin-top: 24px;
  margin-bottom: 5px;
  width: 100% !important;

  height: 80px;


  background-color: #FFFFFF;


  background-color: var(--color-fondo);
  border: 1px solid #E1EBF4;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
}

.card-docs-grid-mui {

  width: 90%;
  margin-top: 24px;
  margin-bottom: 5px;

  padding: 16px 16px 16px 16px;


  background-color: #FFFFFF;


  background-color: var(--color-fondo);
  border: 1px solid #E1EBF4;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
}

.btn-m {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 5px;

  padding: 16px 24px;
  grid-gap: 16px;
  gap: 16px;
  height: 80px;
  margin-left: 16px;
  margin-right: 16px;

  top: 210px;
  bottom: 100px;

}

.card-docs-agenda {

  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 16px 24px;
  grid-gap: 16px;
  gap: 16px;
  width: 390px;
  height: 80px;
  left: 25px;
  top: 210px;
  bottom: 100px;
  background: #FFFFFF;
  border: 1.4px solid #D0D0D0;
  border-radius: 10px;
}

.arrow-menu {
  width: 24px;
  height: 24px;
}

.arrow-menu-home-mui {
  width: 24px;
  height: 24px;
  margin-top: 16px;
  margin-left: 10px;
}


.img-estados {
  width: 358px;
  height: 257px;
  margin-left: 16px;
  margin-right: 16px;
}

a {
  text-decoration: none;
}

.btn-prueba {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px 0px;
  grid-gap: 8px;
  gap: 8px;
  width: 365px;
  height: 58px;
  left: 24px;
  margin-top: 30px;
  margin-bottom: 5px;
  background: linear-gradient(0deg, #81A1F8, #81A1F8), #81A1F8;
  /* M3/Elevation Light/1 */

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.btn-prueba-blanco {
  width: 365px;
  height: 58px;
  margin-top: 8px;
  margin-bottom: 20px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), #FFFFFF;
  border: 1px solid #6C9FFF;
  /* M3/Elevation Light/1 */

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.btn-prueba-blanco-grid {
  width: 365px;
  height: 58px;
  margin-top: 8px;
  margin-bottom: 20px;
}

.btn-prueba-ingreso {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px 0px;
  grid-gap: 8px;
  gap: 8px;
  width: 330px;
  height: 58px;
  left: 24px;
  margin-top: 30px;
  margin-bottom: 5px;
  background: linear-gradient(0deg, #81A1F8, #81A1F8), #81A1F8;
  /* M3/Elevation Light/1 */

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.centrado {
  display: flex;
  justify-content: center;
  align-items: center;
}

.centrado-mensaje {
  margin-left: 40px;
  margin-right: 40px;
  width: 80%;
}

.rounded-start {
  width: 58px;
  height: 58px;
  border-radius: 100%;
}

.img-data-perfil {
  width: 48px;
  height: 48px;
  margin-top: 18px;
  margin-left: 16px;
  margin-bottom: 18px;

}

.text-center {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
}

.text-docs {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  text-align: start;
}

.text-reu {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  text-align: start;
  margin-top: 1px;
}

.text-blue {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  color: #6C9FFF;
  color: var(--color-primario-btn);
}

.danger-text {
  color: #FF864B;
  color: var(--color-naranja-duppla);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.text-btnloggout {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 15px 0px;
  top: -50px;
}

.text-space {
  margin-top: 15px;
  padding: 0px 0px 0px 0px;
}

/*Estilos componenete inmueble-property*/

.card-inmueble {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  padding: 16px 24px;
  grid-gap: 16px;
  gap: 16px;
  width: 390px;
  height: 180px;
  left: 25px;
  right: 25px;
  top: 210px;
  bottom: 100px;
}

.card-inmueble-first {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  padding: 16px 24px;
  grid-gap: 16px;
  gap: 16px;
  width: 390px;
  height: 180px;
  left: 25px;
  right: 25px;
  top: 210px;
  bottom: 100px;
}

.card-inm-value {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  padding: 16px 24px;
  grid-gap: 16px;
  gap: 16px;
  width: 390px;
  height: 180px;
  left: 25px;
  right: 25px;
  margin-top: 80px;
  bottom: 100px;
}

.card-body {
  padding-bottom: 5px;
  width: 280px;
  margin-left: -5px;
}

.card-body-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-body-docs {
  padding-bottom: 5px;

}

.card-perfil-datos {
  margin-top: -10px;
  margin-bottom: 15px;
}

.description-apt {
  margin: 16px 16px 16px 16px;
}

.btn-d-cancel {
  box-sizing: border-box;
  position: relative;
  width: 160px;
  height: 52px;
  margin-left: 10px;
  top: 24px;
  background: #81A1F8;
  color: #FFFFFF;
  color: var(--color-fondo);
  /* Neutral / Grey 800 */

  border: 0.5px solid #323F4B;
  border-radius: 10px;
}

.btn-d-aceptar {
  box-sizing: border-box;

  position: relative;
  width: 160px;
  height: 52px;
  top: 24px;
  margin-right: 10px;
  background: #FFFFFF;
  background: var(--color-fondo);
  color: #323F4B;
  /* Neutral / Grey 800 */
  border: 1px solid #323F4B;
  border-radius: 10px;
}

.space-btn-property {
  margin-top: 34px;
  margin-bottom: 24px;
}

.card-state-properties {
  display: flex;
  justify-content: flex-start;
  align-items: start;
  height: 20px;
  width: 360px;
  margin-bottom: 6px;
}

.card-state-properties-home {
  display: flex;
  justify-content: flex-start;
  align-items: start;
  grid-gap: 16px;
  gap: 16px;
  width: 380px;
  margin-bottom: 6px;
  margin-top: 5px;
  margin-left: 30px;
}

.nav-item {
  border-top: 1px solid #f3f4f6;
  border-bottom: 1px solid #f3f4f6;
  border-left: 1px solid #ffffff;
  border-right: 1px solid #ffffff;
}

.img-navbar-home {
  display: flex;
  justify-content: center;
  align-items: center;

}

.img-navbar {
  width: 50px;
  height: 5px;
}

.text-navbar-options {
  margin: 12px 10px -10px 30px;
}

.img-state-propety {
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
}

.dropdown-menu-cambio {
  width: 280px;
  height: 230px;
  margin-bottom: 10px;

  background: #F9F9F9;
  border-radius: 10px;
}

.list-group-item {
  width: 260px;
  height: 30px;
  background: #F9F9F9;
  border: #F9F9F9;
}

.space-text-span {
  margin-bottom: -20px;
}

.space-property-evaluation {
  margin-bottom: 20px;
}

/*Sección de oferta*/
.container-offer {
  height: 900px !important;
}

.img-offer-conatiner {
  align-items: center;
  width: 360px;
  height: 350px;

  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 50px;

}

.offer-container-link {
  margin-top: 30px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 60px;
}

.text-offer-link {
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  color: #0A3323;
  color: var(--color-cimiento-duppla);
}

.text-name-home {

  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height */
  margin-top: 34px !important;
  margin-bottom: 1px;
  width: 200px;

  display: flex;
  align-items: center;
  letter-spacing: -0.015em;

  color: #FFFFFF;
}

.icon-navbar-home {
  margin-bottom: 29px;
  margin-left: -12px;
}

.navbar-move {
  height: 500px !important;
  border-radius: 12px 12px 0px 0px;
}

/*Responsive*/


@media screen and (min-width: 270px) {

  .container-inm-home-grid {
    width: 300px !important;
  }

  .card-body-form {

    margin-bottom: 31px;
    width: 260px;
    height: auto;
    background-color: #ffffff;
    border: 1px solid #ced4da;
    padding: 1.25rem !important;
    background-clip: padding-box;
    border-radius: 10px;

  }

  .Container-cards-payment-customer-mui {

    width: 100% !important;
    margin-bottom: 5px;
    height: 90px;
    background-color: #FFFFFF;
    background-color: var(--color-fondo);
    border: 1px solid #E1EBF4;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 12px;

  }

  .cards-payment-jsx-mui {
    width: 100% !important;

  }

  .text-label-form-payment {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    color: #0A3323;
    color: var(--color-cimiento-duppla);
   
  }

}

@media screen and (min-width: 360px) {

  .btn-prueba-blanco-grid {
    width: 300px;
    height: 58px;
    margin-top: 8px;
    margin-bottom: 20px
  }

  .btn-prueba-blanco {
    width: 300px;
    height: 58px;
    margin-top: 8px;
    margin-bottom: 20px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), #FFFFFF;
    border: 1px solid #6C9FFF;
    /* M3/Elevation Light/1 */
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    border-radius: 10px;

  }

  .btn-prueba {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 6px 0px;
    grid-gap: 8px;
    gap: 8px;
    width: 300px;
    height: 58px;
    left: 24px;
    margin-top: 30px;
    margin-bottom: 5px;
    background: linear-gradient(0deg, #81A1F8, #81A1F8), #81A1F8;
    /* M3/Elevation Light/1 */

    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
  }

  .btn-prueba-ingreso {

    grid-gap: 8px;

    gap: 8px;
    width: 320px;
    height: 58px;
    left: 24px;
    margin-top: 30px;
    margin-bottom: 5px;
    background: linear-gradient(0deg, #81A1F8, #81A1F8), #81A1F8;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
  }

  .input-register {

    width: 320px;
    height: 52px;
    margin-top: 10px;
    margin-bottom: 10px;

  }

  hr {
    /*  position: absolute; */

    width: 66px;
    height: 0px;
    left: 80px;
    top: 67px;
    margin-top: 58px;
    margin-left: 1px;
    margin-bottom: 35px;
    fill: #ffffff;

    border: 1px solid #ffffff !important;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    background-color: #ffffff !important;

  }

  .vertical-line {
    position: absolute;
    margin-left: 114px;
    margin-top: -62px;
    margin-bottom: 36px;
    width: 1px;
    height: 61px;
    background-color: rgb(255, 255, 255);
    border: 1px solid #E7E7E7;

  }

  .img-duppla {
    position: absolute;
    padding: 0px;
    width: 80px;
    height: 28px;
    margin-left: 20px;
    margin-top: 2px;
    margin-bottom: 10px;
  }

  .text-space-inm-dropdown {
    margin-top: 13px;
    margin-left: 35px;
    width: 60px;
  }

  .card-dropdown-inm {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -8px;
    margin-bottom: 5px;

    padding: 16px 24px;
    grid-gap: 16px;
    gap: 16px;
    width: 260px;
    height: 58px;
    margin-left: -8px;
    margin-right: 10px;
    border-radius: 12px;

    background: #ffffff;
  }

  .bell {

    height: 24px;
    width: 24px;
    margin-left: 140px;
    margin-right: 30px;
    margin-top: -46px;


  }

  .bell-mui {
    height: 24px;
    width: 24px;
    margin-left: 10px;
    margin-top: -46px;
    color: #FFFFFF;
    fill: #FFFFFF;

  }

  .btn-pago-custumer {
    width: 344px;
    height: 58px;
    margin-top: 26px;
    margin-bottom: 26px;
    background: linear-gradient(0deg, #81A1F8, #81A1F8), #81A1F8;
    border: 1px solid #81A1F8;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
  }

  .card-body-form-two {

    display: grid;
    grid-template-columns: 1fr;
    margin-left: 28px;
    margin-right: 28px;
    grid-gap: 10px;
    margin-bottom: 31px;
    /*  width: 320px; */
    height: auto;
    background-color: #ffffff;
    border: 1px solid #ced4da;
    padding: 1.25rem !important;
    background-clip: padding-box;
    border-radius: 10px;

  }

  .imgs-navbar-mui {
    margin-left: 24px !important;
  }

  .separacion-text-mui {
    margin-top: -6px;
  }

  .container-inm-home-grid {
    width: 310px !important;
  }

  .text-white-profile {
    color: #FFFFFF;
    color: var(--color-fondo);
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    margin-top: 24px;
    margin-left: -24px;
    width: 200px;
  }

  .card-body-form {

    margin-bottom: 31px;
    width: 320px;
    height: auto;
    background-color: #ffffff;
    border: 1px solid #ced4da;
    padding: 1.25rem !important;
    background-clip: padding-box;
    border-radius: 10px;

  }

  .Container-cards-payment-customer-mui {

    width: 100% !important;
    /*   margin-top: 24px; */
    margin-bottom: 5px;
    height: 90px;
    background-color: #FFFFFF;
    background-color: var(--color-fondo);
    border: 1px solid #E1EBF4;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 12px;

  }

  .Container-cards-payment-bar {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 24px;
    margin-bottom: 5px;

    width: 100%;

    padding: 16px 24px;
    grid-gap: 16px;
    gap: 16px;
    height: 270px;
    margin-left: 16px;
    margin-right: 16px;

    border: 1px solid #E1EBF4;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 12px;

  }

  .text-name-home {

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height */
    margin-top: 34px !important;
    margin-bottom: 1px;
    width: 200px;

    display: flex;
    align-items: center;
    letter-spacing: -0.015em;

    color: #FFFFFF;
  }

  .tooltip-payment-mui {
    position: absolute;
    margin-top: -44px;
    margin-left: 120px;
    width: 200px;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    background-color: #ffffff;
    color: #0A3323;
    padding: 8px;
    border-radius: 4px;
    font-size: 14px;
    opacity: 1;
    box-shadow: 0px 0px 10px rgba(115, 113, 113, 0.15);
  }

  .margin-box-mui-payment {
    margin-left: 32px !important;
  }

  .loanding {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    /*  background-color: #F1FFEB; */
    border-radius: 10px;
  }

  .loanding-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
  }

  .text-loandig {
    color: #0A3323;
    margin-bottom: 20px;
  }

  .loanding-state-mui {
    width: 140px;
    height: 140px;
  }

  .video-docs {
    height: 300px;
    width: 300px;

  }

  .video-docs-text-date {
    height: auto;
    width: 300px;
  }

  .cards-payment-jsx-mui {
    width: 356px !important;

  }

  .text-label-form-payment {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    color: #0A3323;
    color: var(--color-cimiento-duppla);
   
  }

}

@media screen and (min-width: 576px) {

  .btn-prueba {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 6px 0px;
    grid-gap: 8px;
    gap: 8px;
    width: 430px;
    height: 58px;
    left: 24px;
    margin-top: 30px;
    margin-bottom: 5px;
    background: linear-gradient(0deg, #81A1F8, #81A1F8), #81A1F8;
    /* M3/Elevation Light/1 */

    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
  }

  .btn-prueba-blanco {
    width: 430px;
    height: 58px;
    margin-top: 8px;
    margin-bottom: 20px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), #FFFFFF;
    border: 1px solid #6C9FFF;
    /* M3/Elevation Light/1 */

    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    border-radius: 10px;

  }

  .btn-prueba-ingreso {

    grid-gap: 8px;

    gap: 8px;
    width: 430px;
    height: 58px;
    padding: 0px 0px 0px 0px;
    margin-top: 30px;
    margin-bottom: 5px;
    margin-left: 10px;
    margin-right: 10px;
    background: linear-gradient(0deg, #81A1F8, #81A1F8), #81A1F8;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
  }

  .img-duppla {
    position: absolute;
    padding: 0px;
    width: 80px;
    height: 28px;
    margin-left: 30px;
    margin-top: 2px;
    margin-bottom: 10px;
  }

  .input-register {

    width: 400px;
    height: 52px;
    margin-top: 10px;
    margin-bottom: 10px;

  }

  .bell {

    height: 24px;
    width: 24px;
    margin-left: 120px;
    margin-right: 30px;
    margin-top: -46px;

  }

  .container-inm-home-grid {
    width: 300px !important;
  }

  .card-body-form {

    margin-bottom: 31px;
    width: 340px;
    height: auto;
    background-color: #ffffff;
    border: 1px solid #ced4da;
    padding: 1.25rem !important;
    background-clip: padding-box;
    border-radius: 10px;

  }

  .Container-cards-payment-customer-mui {

    width: 100% !important;
    margin-top: 24px;
    margin-bottom: 5px;

    height: 90px;
    background-color: #FFFFFF;
    background-color: var(--color-fondo);
    border: 1px solid #E1EBF4;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 12px;

  }

  .text-name-home {

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height */
    margin-top: 34px !important;
    margin-bottom: 1px;
    width: 200px;

    display: flex;
    align-items: center;
    letter-spacing: -0.015em;

    color: #FFFFFF;
  }

  .margin-box-mui-payment {

    margin-left: 3px !important;

  }

  .video-docs {
    height: 360px;
    width: 400px;

  }

  .video-docs-text-date {
    height: auto;
    width: 1040px;
  }

  .cards-payment-jsx-mui {
    width: 460px !important;
    height: 90px;
  }

  .text-label-form-payment {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400;
    color: #0A3323;
    color: var(--color-cimiento-duppla);
   
  }

}

/*Dispositivos medianos (tabletas, 768px y más)*/
@media screen and (min-width: 768px) {
  .container-inm-home-grid {
    width: 400px;
  }

  .Container-cards-payment-customer-mui {

    width: 600% !important;
    margin-bottom: 5px;
    height: 90px;
    background-color: #FFFFFF;
    background-color: var(--color-fondo);
    border: 1px solid #E1EBF4;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 12px;

  }

  .margin-box-mui-payment {

    margin-left: 2px !important;

  }

  .loanding-state-mui {
    width: 200px;
    height: 200px;
  }

  .video-docs {
    height: 360px;
    width: 640px;

  }

  .video-docs-text-date {
    height: auto;
    width: 640px;
  }

  .cards-payment-jsx-mui {
    width: 480px !important;

  }

  .text-label-form-payment {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400;
    color: #0A3323;
    color: var(--color-cimiento-duppla);

  }
}

/* Dispositivos grandes (escritorios, 992px y más)*/
@media screen and (min-width: 992px) {
  .container-inm-home-grid {
    width: 400px;
  }

  .card-body-form {

    margin-bottom: 31px;
    width: 100%;
    height: auto;
    background-color: #ffffff;
    border: 1px solid #ced4da;
    padding: 1.25rem !important;
    background-clip: padding-box;
    border-radius: 10px;

  }

  .Container-cards-payment-customer-mui {

    width: 100% !important;
    margin-top: 24px;
    margin-bottom: 5px;
    padding: 16px 24px;
    height: 90px;
    background-color: #FFFFFF;
    background-color: var(--color-fondo);
    border: 1px solid #E1EBF4;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 12px;

  }

  .text-name-home {

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height */
    margin-top: 34px !important;
    margin-bottom: 1px;
    width: 280px;

    display: flex;
    align-items: center;
    letter-spacing: -0.015em;

    color: #FFFFFF;
  }

  .tooltip-payment-mui {
    position: absolute;
    margin-top: -40px;
    margin-left: 140px;
    width: 230px;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    background-color: #ffffff;
    color: #0A3323;
    padding: 8px;
    border-radius: 4px;
    font-size: 14px;
    opacity: 1;
    box-shadow: 0px 0px 10px rgba(115, 113, 113, 0.15);
  }

  .margin-box-mui-payment {
    margin-left: 5px !important;
  }

  .loanding-state-mui {
    width: 160px;
    height: 160px;
  }

  .cards-payment-jsx-mui {
    width: 374px !important;

  }

  .text-label-form-payment {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400;
    color: #0A3323;
    color: var(--color-cimiento-duppla);

  }
}

/* Dispositivos X-Large (escritorios grandes, 1200px y más)*/
@media (min-width: 1200px) {
  .bell-mui {
    height: 24px;
    width: 24px;
    margin-left: -10px;
    margin-top: -46px;
    color: #FFFFFF;
    fill: #FFFFFF;

  }

  .container-inm-home-grid {
    width: 400px !important;
  }

  .text-white-profile {
    color: #FFFFFF;
    color: var(--color-fondo);
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    margin-top: 34px;
    margin-left: -24px;
    width: 300px;
    /* identical to box height */

  }

  .card-body-form {

    margin-bottom: 31px;
    width: 100%;
    height: auto;
    background-color: #ffffff;
    border: 1px solid #ced4da;
    border-radius: 10px;

  }

  .Container-cards-payment-customer-mui {

    width: 100% !important;
    margin-top: 24px;
    margin-bottom: 5px;
    padding: 16px 24px;
    height: 90px;
    background-color: #FFFFFF;
    background-color: var(--color-fondo);
    border: 1px solid #E1EBF4;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 12px;

  }

  .Container-cards-payment-bar {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 24px;
    margin-bottom: 5px;
    width: 84%;
    padding: 16px 24px;
    grid-gap: 16px;
    gap: 16px;
    height: 270px;
    margin-left: 16px;
    margin-right: 16px;
    border: 1px solid #E1EBF4;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 12px;

  }

  .text-name-home {

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height */
    margin-top: 34px !important;
    margin-bottom: 1px;
    width: 320px;

    display: flex;
    align-items: center;
    letter-spacing: -0.015em;

    color: #FFFFFF;
  }

  .tooltip-payment-mui {
    position: absolute;
    margin-top: -40px;
    margin-left: 160px;
    width: 280px;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    background-color: #ffffff;
    color: #0A3323;
    padding: 8px;
    border-radius: 4px;
    font-size: 14px;
    opacity: 1;
    box-shadow: 0px 0px 10px rgba(115, 113, 113, 0.15);
  }

  .margin-box-mui-payment {

    margin-left: 1px !important;

  }

  .loanding-state-mui {
    width: 160px;
    height: 160px;
  }

  .video-docs {
    height: 560px;
    width: 1040px;

  }

  .video-docs-text-date {
    height: auto;
    width: 1040px;
  }

  .cards-payment-jsx-mui {
    width: 374px !important;

  }

  .text-label-form-payment {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400;
    color: #0A3323;
    color: var(--color-cimiento-duppla);

  }
}

/* Dispositivos XX-Large (escritorios más grandes, 1400px y más)*/
@media (min-width: 1600px) {
  .bell-mui {
    height: 24px;
    width: 24px;
    margin-left: -10px;
    margin-top: -46px;
    color: #FFFFFF;
    fill: #FFFFFF;

  }

  .container-inm-home-grid {
    width: 400px !important;
  }

  .btn-prueba-blanco-grid {
    width: 430px;
    height: 58px;
    margin-top: 8px;
    margin-bottom: 20px;
  }

  .text-white-profile {
    color: #FFFFFF;
    color: var(--color-fondo);
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    margin-top: 34px;
    margin-left: -24px;
    width: 300px;
  }
  .card-body-form {
    margin-bottom: 31px;
    width: 100%;
    height: auto;
    background-color: #ffffff;
    border: 1px solid #ced4da;
    padding: 1.25rem !important;
    background-clip: padding-box;
    border-radius: 10px;
  }

  .Container-cards-payment-customer-mui {


    width: 100% !important;
    margin-top: 24px;
    margin-bottom: 5px;
    padding: 16px 24px;
    height: 90px;
    background-color: #FFFFFF;
    background-color: var(--color-fondo);
    border: 1px solid #E1EBF4;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 12px;

  }

  .Container-cards-payment-bar {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 24px;
    margin-bottom: 5px;

    width: 84%;

    padding: 16px 24px;
    grid-gap: 16px;
    gap: 16px;
    height: 270px;
    margin-left: 16px;
    margin-right: 16px;

    border: 1px solid #E1EBF4;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 12px;

  }

  .text-name-home {

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height */
    margin-top: 34px !important;
    margin-bottom: 1px;
    width: 320px;

    display: flex;
    align-items: center;
    letter-spacing: -0.015em;
    color: #FFFFFF;
  }

  .margin-box-mui-payment {
    margin-left: 4px !important;
  }

  .loanding-state-mui {
    margin-top: 24px !important;
    width: 160px;
    height: 160px;
  }


  .video-docs {
    height: 560px;
    width: 1040px;

  }

  .video-docs-text-date {
    height: auto;
    width: 1040px;
  }

  .cards-payment-jsx-mui {
    width: 374px !important;

  }
  .text-label-form-payment {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400;
    color: #0A3323;
    color: var(--color-cimiento-duppla);
   
  }


};
.profile-form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 110px;

  bottom: 100px;
  padding: 0px 0px 0px 0px;
  margin-bottom: 5px;
  background: linear-gradient(60.01deg, #0A3323 19.88%, #C5F5CA 217.71%);
  background-size: 100%;
  background-repeat: no-repeat;
  border-bottom-left-radius: 60% 18%;
  border-bottom-right-radius: 60% 18%;
}

.navbar-form {
  background-color: #0A3323;
}

.navbar-brand {
  color: #0A3323;
}

.btn-primary {
  background-color: #FF864B;
  border-color: #FF864B;
}
@font-face {
  font-family: 'Rustica';
  src: url(/static/media/RusticaMedium.ed65affe.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Rustica';
  src: url(/static/media/RusticaLight.37f40ff1.ttf) format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Rustica';
  src: url(/static/media/RusticaRegular.dcda657e.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNowText-Light';
  src: url(/static/media/HelveticaNowTextLight.226a5339.otf) format('opentype');
  font-weight: 200;
  font-style: normal;
}

body {
  font-family: 'Rustica', sans-serif;
  background-size: cover;
}


.configuracion-inicial{
  display:flex
}

.no-alinear-centro { margin: 0 auto; }

.container-form-centrado-mantenimiento {
  display: flex;
  justify-content: center;
  align-items: center;
  
  margin-left: 24px !important;
  margin-right: 34px;
  border-radius: 5px;

}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
  background-color: #9ED19A;  
}

/* Custom focus ring */
.btn-form:focus,
.form-control:focus {
  box-shadow: 0 0 0 0.2rem rgba(197, 245, 202, 0.5); 
}

.seccion-titulo {
  color: #42723f;
  margin-top: 20px;
  margin-bottom: 20px;
}

/* Custom Title */
.custom-h4 {
  font-family: 'Rustica', sans-serif;
  font-weight: 500;
  font-size: 24px;
}

/* Custom Logo */
.custom-logo {
  align-self: flex-end;
}

.back-button {
  display: flex;
  align-items: center;
  padding-left: 10px;
  /* Adjust this value as needed */
  background-color: transparent;
  border: none;
}


.back-button svg {
  fill: white;
  width: 32px;
  /* Adjust size here */
  height: 32px;
  /* Adjust size here */

}

.custom-logo {
  height: 20px;
  /* Adjust this value as needed */
  width: auto;
  align-self: center;
}

.dropdown-item:hover {
  color: black;
  /* Change as desired, this will change the color of the text */
  background-color: lightgreen;
  /* This will change the background color */
}

.custom-h3 {
  font-family: 'Rustica', sans-serif;
  font-weight: 500;
  font-size: 22px;
  /* Change this to adjust size */
  color: #0A3323;
  justify-content: start;
  text-align: start; 
}

.custom-h4 {
  font-family: 'Rustica', sans-serif;
  font-weight: 400;
  font-size: 20px;
  /* Change this to adjust size */
  justify-content: start;
  text-align: start;  

}

/* Set the textarea font */
textarea {
  font-family: 'HelveticaNowText-Light', sans-serif;
  width: 300px;
  height: 100px;
}

/* Add spacing above the checkbox */
.form-check.custom-checkbox {
  margin-top: 20px;
  /* Adjust the value as needed */
}


.card-body-form-two { 
  margin-bottom: 31px;
  height: auto;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  padding: 1.25rem !important;
  background-clip: padding-box;
  border-radius: 10px;

}
code {
    font-family: Arial, Helvetica, sans-serif;
}

/* variables de colores duppla*/
:root {
    --color-primario-btn: #6C9FFF;
    --color-fondo: #FFFFFF;
    --color-naranja-duppla: #FF864B;
    --color-verde-duppla: #C5F5CA;
    --color-cimiento-duppla: #0A3323;
    --color-sombra-duppla: #EEEFF3;
    --color-fondo-gris: #F9F9F9;
}

@font-face {
    font-family: 'Rustica';
    src: url(/static/media/RusticaMedium.ed65affe.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Rustica';
    src: url(/static/media/RusticaLight.37f40ff1.ttf) format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Rustica';
    src: url(/static/media/RusticaRegular.dcda657e.ttf) format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNowText-Light';
    src: url(/static/media/HelveticaNowTextLight.226a5339.otf) format('opentype');
    font-weight: 200;
    font-style: normal;
}

.text-blue-init {

    color: #6C9FFF;

    color: var(--color-primario-btn)
}

.text-green-init {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    color: #0A3323;
    color: var(--color-cimiento-duppla);
}

/*Sección Perfil inicio custumer*/
.profile-inicio {
    display: flex;
    flex-direction: initial;
    justify-content: space-between;
    align-items: center;

    left: 0px;
    top: 0px;
    bottom: 100px;
    padding: 0px 0px 0px 0px;
    margin-bottom: 31px;

    background: #FFFFFF;

    background: var(--color-fondo);
    background-size: 100%;
    background-repeat: no-repeat;
    border-bottom-right-radius: 16%;
    border-bottom-left-radius: 16%;

}


.img-user-init {
    width: 64px;
    height: 63px;
    margin-top: 30px;
    margin-left: 16px;
    margin-bottom: 10px;

}

.container-progress {

    align-items: center;
    height: 800px;
    margin-left: 32px;
    margin-right: 32px;
    background: #FFFFFF;
    border-radius: 20px;
}

.title-init {
    padding-top: 6px;
}

.title-init-progressbar {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 24px;
    font-family: 'Rustica' !important;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;

    font-family: Arial, Helvetica, sans-serif;
    color: #0A3323;

}

.progres-img {
    margin-top: 20px;
    width: 340px;
    height: 160px;
}

.text-align-custumer {
    margin-top: 10px;
}

.text-number-custumer {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
}

.text-space-month {
    margin-top: 13px;
    margin-left: 25px;
}

.text-space-property {
    width: 100px;
    margin-top: 10px;
    margin-left: 10px;
    color: #6C9FFF;
    font-size: 16px;
}


.card-docs-init {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;

    padding: 16px 24px;
    grid-gap: 16px;
    gap: 16px;
    height: 20px;
    margin-left: 16px;
    margin-right: 16px;

    border-radius: 5px;
    top: 210px;
    bottom: 100px;

}

.card-docs-init-customer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -5px;
    margin-bottom: 5px;

    padding: 16px 24px;
    grid-gap: 16px;
    gap: 16px;
    height: 20px;
    margin-left: 40px;
    margin-right: 20px;

    border-radius: 5px;
}

.card-docs-property {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 5px;
    margin-bottom: 5px;

    padding: 10px 10px;
    width: 140px;
    height: 20px;
    border-radius: 5px;
}

.card-body-property {
    width: 110px;
    margin-left: -10px;
}

.text-dropdown-property {
    width: 80px;
    margin-left: -10px;
    margin-right: 4px;
}

.container-movil {
    position: relative;
}

.prueva-mo {
    position: absolute;
}

.card-header {
    border: none !important;
    position: relative;
    width: 290px;
}

.card {
    border: none !important;
    margin-left: -38px;
}

.card-col-none {
    border: none !important;
    border-color: #ffffff;
    border: 1px solid #ffffff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}

.card-dropdown-property {
    background-color: #ffffff;
}


.btn-pago-custumer {
    width: 365px;
    height: 58px;
    margin-top: 20px;
    margin-bottom: 20px;
    background: linear-gradient(0deg, #81A1F8, #81A1F8), #81A1F8;
    border: 1px solid #81A1F8;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
}


.container-form-centrado-customer-card {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-left: 16px;
    margin-right: 16px;
    border-radius: 5px;

}

.accordion-item-mui {
    width: 378px !important;

}

.ul {
    text-decoration: none;
    list-style: none;
    border: #F9F9F9;
}

.text-space-dropdown {
    margin-right: 60px;
}

.icon-drop {
    margin-left: 250px;
}

.icon-drop-property {
    margin-left: 124px;
}

.dropdown-menu-init {
    padding: 0px 0px 0px 0px;
    box-sizing: border-box;
    width: 262px;
    height: 290px;
    margin-right: -7px;
    margin-left: 150px;
    background: #ffffff;
    border: 1px solid #E1EBF4;
    border-radius: 8px;
}

.card-dropdown {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    margin-bottom: 5px;

    padding: 16px 24px;
    grid-gap: 16px;
    gap: 16px;
    height: 46px;
    margin-left: 16px;
    margin-right: 16px;

    top: 210px;
    bottom: 100px;

    background-color: #FFFFFF;

    background-color: var(--color-fondo);
    border: 1px solid #E1EBF4;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
}

.acordion-tamaño {
    width: 360px;
}


.line-custumer {
    height: 2px;
    width: 380px;
}

.img-btn-pagos-custumer {
    height: 32px;
    width: 32px;
    margin-right: 10px;
    margin-bottom: 10px;
}

.container-btns {
    margin-top: 60px;
}

.text-btn-custumer-menu {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
}

.navbar-custumer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    grid-gap: 4px;
    gap: 4px;

    position: absolute;
    width: 32px;
    height: 42px;
    margin-left: 10px;
    margin-right: 10px;
    border-color: #6C9FFF;
}

.btn-modal-cerrar {

    margin: 30px 10px 10px 25px;
}

.btn-modal-pago {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px 16px;
    grid-gap: 10px;
    gap: 10px;


    width: 220px;
    height: 120px;
    left: 97px;
    top: 380px;

    background: #FFFFFF;
    border: 1px solid #6C9FFF;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
}

.text-blue-modal {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    color: #6C9FFF;
    color: var(--color-primario-btn);
}

/*  nuevo */


.container-btn-wrapper {
    max-width: 600px;
    border: 1px solid #FFFFFF;
    border: 1px solid var(--color-fondo);
    display: flex;
    overflow-x: auto;
    margin-top: 20px;


}

.container-btn-wrapper::-webkit-scrollbar {
    width: 0px;

}

.btn-wrapper {

    width: 70px;
    height: 70px;
    margin-left: 15px;
    top: 0px;
    background: #EFEFEF;
    border-radius: 36px;
}

.btn-wrapper-one {

    width: 70px;
    height: 70px;
    margin-left: 6px;

    background: #EFEFEF;
    border-radius: 36px;
}

.img-btn-wrapper {
    margin-left: 19px;
    margin-right: 5px;
    margin-top: 18px;
    margin-bottom: 5px;
}

.img-btn-wrapper-history {

    margin-left: 23px;
    margin-right: 6;
    margin-top: 25px;
    margin-bottom: 5px
}

.img-btn-wrapper-problem {

    margin-left: 17px;
    margin-right: 6;
    margin-top: 17px;
    margin-bottom: px
}


.text-btn-wrapper {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    text-align: center;
}

.space-btn-wrapper {
    margin-left: 19px;
    margin-right: 17px;
    margin-top: 15px;
    margin-bottom: 5px;



}


.btn-disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.btn-disabled-offert {
    background-color: #ffffff;
    color: #999;
    cursor: not-allowed;
}

.btn-custumer-disabled {
    border: none !important;
}

.card-new {

    width: 340px;


}

.card {
    width: 340px;
    margin-left: 4px;
    margin-right: 10px;
    background-color: #ffffff;

}


.text-space-card-c {
    width: 120px;

}

.card-body-docs-c {
    width: 186px;
    margin-left: -10px;
    background-color: rgb(255, 255, 255);
}


.span-pago {
    margin-left: 24px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.text-space-custumer {
    width: 260px;
    margin-left: 1px;
    margin-right: 3px !important;

}

.text-space-property {

    margin-left: 50px;
    margin-right: 10px !important;

}

.space-title-p {
    width: 80px;
}
.space-title-prueba-mui{
    margin-left: -60px !important;
}

.space-title-docs-home {
    width: 240px;
    margin-top: -6px !important;
    margin-left: 29px;
    
}

.space-title-docs-home-mui {
    width: 240px;
    margin-top: -6px !important;
    margin-left: 60px !important;
    
}
.acordion-item-border {
    border: none !important;
    margin-top: -10px !important;
}

.accordion-custumer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    margin-bottom: 5px;

    margin-left: 40px;
    margin-right: 40px;

    border: 1px solid #E1EBF4;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
}

.accordion-property {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    margin-bottom: 5px;

    margin-left: 40px;
    margin-right: 40px;

    border: none;

    border-radius: 12px;
}


.container-inicio-graph {

    display: flex;
    justify-content: center;
    align-items: center;

    margin-right: 20px;
    margin-left: 20px;
    margin-top: 50px;

    background-color: rgb(255, 255, 255);
    /* Stroke Color */
    z-index: 1;

}

.grafict-container-inicio-one {

    width: 374px;
    height: 540px;
    margin-right: 74px;
    margin-left: 30px;
    margin-bottom: 10px;
    background: #ffffff;
    /* Stroke Color */

    border: 1px solid #EFF0F6;
    /* Tile Dropshadow */

    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    z-index: 1;
}

.text-inicio-gra {

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;

    font-size: 14px;
    margin-top: 5px;
    margin-left: -1px;
    width: 150px;
    line-height: 16px;
    /* identical to box height, or 145% */

    display: flex;
    align-items: center;
    letter-spacing: 0.15px;
}

.prueba-espacio-img-verde {
    margin-left: 10px;
}

.prueba-inicio-espacio-uno {
    background-color: #0A3323;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    color: #0A3323;
    height: 58px;
    width: 80px;
}

.prueba-inicio-espacio-dos {
    background-color: #C5F5CA;
    color: #C5F5CA;
    height: 58px;
    width: 160px
}

.prueba-inicio-espacio-tres {
    background-color: #81A1F8;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    height: 58px;
    width: 80px;
}

.card-docs-grafic {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 130px;
    margin-bottom: 5px;

    padding: 16px 24px;
    grid-gap: 16px;
    gap: 16px;
    height: 20px;
    margin-left: 40px;
    margin-right: 20px;
    border-radius: 5px;
    top: 210px;
    bottom: 100px;
}

.card-docs-grafic-two {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -10px;
    margin-bottom: 2px;

    padding: 16px 24px;
    grid-gap: 16px;
    gap: 16px;
    height: 20px;
    margin-left: 40px;
    margin-right: 20px;

    border-radius: 5px;
    top: 210px;
    bottom: 100px;
}


.sobrepuesto {
    z-index: 2;
    margin-top: -262px;
    margin-left: 2px;
    flex-direction: row;
    justify-content: center;
    text-align: center;
}

.tooltip-container {
    position: relative;
}

.tooltip {
    position: absolute;
    margin-top: -24px;
    margin-left: 120px;
    width: 200px;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    background-color: #ffffff;
    color: #0A3323;
    padding: 8px;
    border-radius: 4px;
    font-size: 14px;
    opacity: 1;
    box-shadow: 0px 0px 10px rgba(115, 113, 113, 0.15);
}


.space-tooltip-mui{
    margin-top: -10px !important;
    margin-left: -8px;
}

.tooltip-grafict {
    position: absolute;
    margin-top: -15px;
    margin-left: 40px;
}

.tooltip-grafict-two {
    position: absolute;
    margin-top: -15px;
    margin-left: 10px;
}

.tooltip-grafict-custumer {
    position: absolute;
    margin-top: -24px;
    margin-left: 142px;
    width: 160px;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    background-color: #ffffff;
    color: #0A3323;
    padding: 8px;
    border-radius: 4px;

    opacity: 1;
    box-shadow: 0px 0px 10px rgba(115, 113, 113, 0.15);
}

.tooltip-text-c {
    font-family: 'Roboto';
    font-style: normal;
    font-size: 11px;

}



.tooltip-customer {
    width: 20px;
    margin-left: 18px;
}

.space-title-dop {
    width: 120px !important;
    margin-left: -20px;
}


.navbar-toggler {
    border: none !important;
}

.navbar-toggler:focus {
    box-shadow: none !important;
}

.navbar-move-bell {
    height: 250px !important;
    border-radius: 12px 12px 0px 0px;
}

.img-duppla-custumer {

    padding: 0px;
    width: 80px;
    height: 28px;
    margin-left: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.container-first-elements {
    width: auto;
    height: 58px;
}

.bell {
    fill: #FFFFFF;
}

.menu-navbar-customer {
    fill: #FFFFFF;

}

.bell {
    height: 24px;
    width: 24px;
    margin-left: 160px;
    margin-right: 30px;
    margin-top: -52px;

}

.container-second-elements {
    width: auto;
    height: 58px;

}

.card-perfil-datos-customer {
    margin-top: -90px;
    margin-bottom: 15px;

}

.icon-navbar-customer {
    margin-bottom: 29px;
    margin-left: -15px;
    margin-top: -72px;


}

.tooltip {
    font-family: 'Roboto';
    font-size: 11px;
}

.horizontal-line {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 280px;
    margin-left: 40px;
    margin-right: 20px;
    height: 0px;
    border: 1px solid #E8E8E8;
}



code {
    font-family: Arial, Helvetica, sans-serif;
}

/* variables de colores duppla*/
:root {
    --color-primario-btn: #6C9FFF;
    --color-fondo: #FFFFFF;
    --color-naranja-duppla: #FF864B;
    --color-verde-duppla: #C5F5CA;
    --color-cimiento-duppla: #0A3323;
    --color-sombra-duppla: #EEEFF3;
    --color-fondo-gris: #F9F9F9;
}
.li {
    list-style: none;
    border: #FFFFFF;

}

.contenedor-progress-donut {
    width: 380px;
}

.grafict-container-one {

    width: 374px;
    height: 480px;

    margin-right: 10px;
    margin-left: 10px;
   

    background: #FFFFFF;
    /* Stroke Color */

    border: 1px solid #EFF0F6;
    /* Tile Dropshadow */

    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
}

.grafict-container-two {

    width: 380px;
    height: 480px;
    margin-right: 18px;
    margin-left: 18px;   

    background: #FFFFFF;
    /* Stroke Color */
    border: 1px solid #EFF0F6;
    /* Tile Dropshadow */
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
}

.text-graph-one {
    margin-top: 30px;
    margin-left: 20px;
    margin-bottom: -10px;

    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
}

.text-graph-two {
    margin-top: 40px;
    margin-left: 10px;
    margin-bottom: -33px;

    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
}


.icon-drop{
    margin-top: 6px;

}

/*Sección de prueba gráficos*/

.container-graph-linear{
    width: 352px;
    background: #ffffff;
    margin-left: 14px;
    margin-right: 14px;
}
.container-history-graph {

    max-width: 390px;
    display: flex;
    overflow-x: auto;
    margin-right: 18px;
    margin-left: 18px;
    margin-top: 50px;

    background: #ffffff;
    /* Stroke Color */

}

/* sección consolidado anual btns*/

.btn-annual-history {

    width: 340px;
    height: 58px;
    margin-top: 20px;
    margin-bottom: 10px;

    background: #FFFFFF;
    border: 1px solid #6C9FFF;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 8px;



}

code {
    font-family: Arial, Helvetica, sans-serif;
}

/* variables de colores duppla*/
:root {
    --color-primario-btn: #6C9FFF;
    --color-fondo: #FFFFFF;
    --color-naranja-duppla: #FF864B;
    --color-verde-duppla: #C5F5CA;
    --color-cimiento-duppla: #0A3323;
    --color-sombra-duppla: #EEEFF3;
    --color-fondo-gris: #F9F9F9;
}

.contenedor-progress-donut {
    width: 380px;
}

.ProgressGradient {

    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 27px;
    margin-right: 27px;
}

.chart-skills {
    position: relative;
    width: 320px;
    height: 160px;
}

.chart-skills::before,
.chart-skills::after {
    position: absolute;
}

.chart-skills::before {
    content: '2023';
    width: inherit;
    height: inherit;
    border: 90px solid rgba(255, 255, 255, 0.3);
    border-bottom: none;
    border-top-left-radius: 165px;
    border-top-right-radius: 165px;
}

.chart-skills::after {
    content: '';
    font-family: Arial, Helvetica, sans-serif;
    left: 50%;
    bottom: 10px;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    font-size: 1.1rem;
    font-weight: bold;
    color: #0A3323;
    color: var(--color-cimiento-duppla)
}

ul {
    list-style: none;
}

.chart-skills li {
    position: absolute;
    top: 100%;
    left: 0;
    width: inherit;
    height: inherit;
    border: 56px solid;
    border-top: none;
    border-bottom-left-radius: 165px;
    border-bottom-right-radius: 165px;
    -webkit-transform-origin: 50% 0;
            transform-origin: 50% 0;

    -webkit-animation-fill-mode: forwards;

            animation-fill-mode: forwards;
    -webkit-animation-duration: .4s;
            animation-duration: .4s;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
}

.chart-skills li:nth-child(1) {
    z-index: 4;
    border-color: #0A3323;
    border-color: var(--color-cimiento-duppla)
}

.chart-skills li:nth-child(2) {
    z-index: 3;
    border-color: #C5F5CA;
    border-color: var(--color-verde-duppla);

}



.chart-skills li:nth-child(4) {
    z-index: 1;
    border-color: #FF864B;
    border-color: var(--color-naranja-duppla)
}



.chart-skills li:nth-child(1) {
    z-index: 4;
    border-color: #0A3323;
    border-color: var(--color-cimiento-duppla);
    -webkit-animation-name: rotate-one;
            animation-name: rotate-one;
}

.chart-skills li:nth-child(2) {
    z-index: 3;
    border-color: #C5F5CA;
    border-color: var(--color-verde-duppla);
    -webkit-animation-name: rotate-two;
            animation-name: rotate-two;
    -webkit-animation-delay: .4s;
            animation-delay: .4s;

}



.chart-skills li:nth-child(4) {
    z-index: 1;
    border-color: #C5F5CA;
    border-color: var(--color-verde-duppla);
    -webkit-animation-name: rotate-four;
            animation-name: rotate-four;
    -webkit-animation-delay: 1.2s;
            animation-delay: 1.2s;
}

.li::marker {
    -webkit-text-emphasis: none;
            text-emphasis: none;
}

@-webkit-keyframes rotate-one {
    100% {
        -webkit-transform: rotate(60.6deg);
                transform: rotate(60.6deg);
        /** 
  * 12% => 21.6deg 
  */
    }
}

@keyframes rotate-one {
    100% {
        -webkit-transform: rotate(60.6deg);
                transform: rotate(60.6deg);
        /** 
  * 12% => 21.6deg 
  */
    }
}




@-webkit-keyframes rotate-four {
    0% {
        -webkit-transform: rotate(70.4deg);
                transform: rotate(70.4deg);
    }

    100% {
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
        /** 
  * 22% => 39.6deg 
  * 140.4 + 39.6 => 180deg 
  */
    }
}




@keyframes rotate-four {
    0% {
        -webkit-transform: rotate(70.4deg);
                transform: rotate(70.4deg);
    }

    100% {
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
        /** 
  * 22% => 39.6deg 
  * 140.4 + 39.6 => 180deg 
  */
    }
}


.chart-skills {
    /* existing rules....*/

    overflow: hidden;
}

.chart-skills li {
    /* existing rules....*/

    -webkit-transform-style: preserve-3d;

            transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
}



code {
    font-family: Arial, Helvetica, sans-serif;
}

/* variables de colores duppla*/
:root {
    --color-primario-btn: #6C9FFF;
    --color-fondo: #FFFFFF;
    --color-naranja-duppla: #FF864B;
    --color-verde-duppla: #C5F5CA;
    --color-cimiento-duppla: #0A3323;
    --color-sombra-duppla: #EEEFF3;
    --color-fondo-gris: #F9F9F9;
}


.percentage-goal{

    margin-top: 100px;
}
.text-bar-goal {
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;

}

.line-bar-goal {
    height: 2px;
    width: 280px;
}

.progress-goal{

margin-top: 100px;
margin-left: 10px;
margin-right: 10px;

}

.bar-one {

    background-color: rgba(10, 51, 35, 0.6);
    width: 50%;
}

.bar-two {

    width: 45%;
    background-color: #C5F5CA;
    border-radius: 5px;
}


.bar-three {
width: 15%;
    background-color:#737682;
}


.data-goal{
    margin-top: 60px;
    margin-left: 24px;

}

.text-space-goal-data{
    margin-right: 10px;

}

.line-data-goal{
    height: 2px;
    width: 320px;
}

.graph-range{
    width: 280px;
    
    background-color: #C5F5CA;

}
.number-range{
 position: relative;    
 margin-left: 80px;
}

/* Style the custom slider */
.custom-slider {
    width: 300px;
    height: 16px;
    -webkit-appearance: none;
            appearance: none;
    background-color: #737682;
    outline: none;
    border-radius: 12px;
    overflow: hidden;
}
  
.custom-slider::-webkit-slider-thumb{

    -webkit-appearance: none;

            appearance: none;
    width: 24px;
    height: 24px;
    background-color: #6C9FFF;
    background-color: var(--color-primario-btn);
    border-radius: 50%;
    border: 2px solid #C5F5CA;
    border: 2px solid var(--color-verde-duppla);
    box-shadow: -407px 0px 0px 400px #C5F5CA;
    box-shadow: -407px 0px 0px 400px var(--color-verde-duppla);
    cursor: pointer;
}
 


code {
    font-family: Arial, Helvetica, sans-serif;
}

/* variables de colores duppla*/
:root {
    --color-primario-btn: #6C9FFF;
    --color-fondo: #FFFFFF;
    --color-naranja-duppla: #FF864B;
    --color-verde-duppla: #C5F5CA;
    --color-cimiento-duppla: #0A3323;
    --color-sombra-duppla: #EEEFF3;
    --color-fondo-gris: #F9F9F9;
}

.container-consolidated-annual{
    background: rgb(255, 255, 255);
}


.card-month{

box-sizing: border-box;

/* Auto layout */
width: 320px;
height: 58px;
margin-left: 15px;
margin-top: 10px;
margin-right: 15px;
margin-bottom: 10px;

background: #FFFFFF;
border: 1px solid #EFEFEF;
box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.15);
border-radius: 8px;
}

.accordion-h-payment{

    
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 5px;

    margin-left: 40px;
    margin-right: 40px;
    min-width: 340px !important;

    border: 1px solid #E1EBF4;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
} 


code {
    font-family: Arial, Helvetica, sans-serif;
}

/* variables de colores duppla*/
:root {
    --color-primario-btn: #81A1F8;
    --color-fondo: #FFFFFF;
    --color-naranja-duppla: #FF864B;
    --color-verde-duppla: #C5F5CA;
    --color-cimiento-duppla: #0A3323;
    --color-sombra-duppla: #EEEFF3;
    --color-fondo-gris: #F9F9F9;
}

.container-notice-date{
    padding: 14px 0px 0px 0px;
    background: #C5F5CA;
    background: var(--color-verde-duppla);
    height: 240px;
}

.text-notice-date {
    margin-top: 20px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
}

.text-notice{
    margin-top: 20px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    
}

.text-notice-two{
    margin-top: -8px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
}

.date-history{
   
    margin-left: 15px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
}

/*Sección pago con demora */
.container-notice-date-two{
    padding: 14px 0px 0px 0px;
    background: #81A1F8;
    background: var(--color-primario-btn);
    height: auto;
}

.text-notice-date-two {
    margin-top: 20px;
    color: #FFFFFF;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    border-radius: 0 0 20px 20px;
    min-width: 340px!important;
}

.text-notice-second {
    margin-top: 20px;    
    color: #FFFFFF;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    
}

.text-notice-second-s{
    margin-top: -8px;
    color: #FFFFFF;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
}



.container-notice-date-three{
    padding: 14px 0px 0px 0px;
    background: #FF864B;
    background: var(--color-naranja-duppla);
    height: 240px;
}
