
code {
    font-family: Arial, Helvetica, sans-serif;
}

/* variables de colores duppla*/
:root {
    --color-primario-btn: #6C9FFF;
    --color-fondo: #FFFFFF;
    --color-naranja-duppla: #FF864B;
    --color-verde-duppla: #C5F5CA;
    --color-cimiento-duppla: #0A3323;
    --color-sombra-duppla: #EEEFF3;
    --color-fondo-gris: #F9F9F9;
}
.li {
    list-style: none;
    border: #FFFFFF;

}

.contenedor-progress-donut {
    width: 380px;
}

.grafict-container-one {

    width: 374px;
    height: 480px;

    margin-right: 10px;
    margin-left: 10px;
   

    background: #FFFFFF;
    /* Stroke Color */

    border: 1px solid #EFF0F6;
    /* Tile Dropshadow */

    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
}

.grafict-container-two {

    width: 380px;
    height: 480px;
    margin-right: 18px;
    margin-left: 18px;   

    background: #FFFFFF;
    /* Stroke Color */
    border: 1px solid #EFF0F6;
    /* Tile Dropshadow */
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
}

.text-graph-one {
    margin-top: 30px;
    margin-left: 20px;
    margin-bottom: -10px;

    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
}

.text-graph-two {
    margin-top: 40px;
    margin-left: 10px;
    margin-bottom: -33px;

    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
}


.icon-drop{
    margin-top: 6px;

}

/*Sección de prueba gráficos*/

.container-graph-linear{
    width: 352px;
    background: #ffffff;
    margin-left: 14px;
    margin-right: 14px;
}
.container-history-graph {

    max-width: 390px;
    display: flex;
    overflow-x: auto;
    margin-right: 18px;
    margin-left: 18px;
    margin-top: 50px;

    background: #ffffff;
    /* Stroke Color */

}

/* sección consolidado anual btns*/

.btn-annual-history {

    width: 340px;
    height: 58px;
    margin-top: 20px;
    margin-bottom: 10px;

    background: #FFFFFF;
    border: 1px solid #6C9FFF;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 8px;



}
