.profile-form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 110px;

  bottom: 100px;
  padding: 0px 0px 0px 0px;
  margin-bottom: 5px;
  background: linear-gradient(60.01deg, #0A3323 19.88%, #C5F5CA 217.71%);
  background-size: 100%;
  background-repeat: no-repeat;
  border-bottom-left-radius: 60% 18%;
  border-bottom-right-radius: 60% 18%;
}

.navbar-form {
  background-color: #0A3323;
}

.navbar-brand {
  color: #0A3323;
}

.btn-primary {
  background-color: #FF864B;
  border-color: #FF864B;
}
@font-face {
  font-family: 'Rustica';
  src: url('./../fonts/Rustica/RusticaMedium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Rustica';
  src: url('./../fonts/Rustica/RusticaLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Rustica';
  src: url('./../fonts/Rustica/RusticaRegular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNowText-Light';
  src: url('./../fonts/HelveticaNow/HelveticaNowTextLight.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
}

body {
  font-family: 'Rustica', sans-serif;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}


.configuracion-inicial{
  display:flex
}

.no-alinear-centro { margin: 0 auto; }

.container-form-centrado-mantenimiento {
  display: flex;
  justify-content: center;
  align-items: center;
  
  margin-left: 24px !important;
  margin-right: 34px;
  border-radius: 5px;

}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
  background-color: #9ED19A;  
}

/* Custom focus ring */
.btn-form:focus,
.form-control:focus {
  box-shadow: 0 0 0 0.2rem rgba(197, 245, 202, 0.5); 
}

.seccion-titulo {
  color: #42723f;
  margin-top: 20px;
  margin-bottom: 20px;
}

/* Custom Title */
.custom-h4 {
  font-family: 'Rustica', sans-serif;
  font-weight: 500;
  font-size: 24px;
}

/* Custom Logo */
.custom-logo {
  align-self: flex-end;
}

.back-button {
  display: flex;
  align-items: center;
  padding-left: 10px;
  /* Adjust this value as needed */
  background-color: transparent;
  border: none;
}


.back-button svg {
  fill: white;
  width: 32px;
  /* Adjust size here */
  height: 32px;
  /* Adjust size here */

}

.custom-logo {
  height: 20px;
  /* Adjust this value as needed */
  width: auto;
  align-self: center;
}

.dropdown-item:hover {
  color: black;
  /* Change as desired, this will change the color of the text */
  background-color: lightgreen;
  /* This will change the background color */
}

.custom-h3 {
  font-family: 'Rustica', sans-serif;
  font-weight: 500;
  font-size: 22px;
  /* Change this to adjust size */
  color: #0A3323;
  justify-content: start;
  text-align: start; 
}

.custom-h4 {
  font-family: 'Rustica', sans-serif;
  font-weight: 400;
  font-size: 20px;
  /* Change this to adjust size */
  justify-content: start;
  text-align: start;  

}

/* Set the textarea font */
textarea {
  font-family: 'HelveticaNowText-Light', sans-serif;
  width: 300px;
  height: 100px;
}

/* Add spacing above the checkbox */
.form-check.custom-checkbox {
  margin-top: 20px;
  /* Adjust the value as needed */
}


.card-body-form-two { 
  margin-bottom: 31px;
  height: auto;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  padding: 1.25rem !important;
  background-clip: padding-box;
  border-radius: 10px;

}